import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { getCookie, setCookieWithExpire } from '../../libraries/session';
import stylesheet from './StickyIHC.scss';
import { trackerMain, getLocation } from '../../libraries/tracker';
import { useFloatingBox } from '../floatingBox/floatingBoxProvider';

const StickyIHC = (props) => {
  const ntf = true;
  const lia = false;

  const [isNotif, setIsNotif] = useState(ntf); // show message
  const [isReady, setisReady] = useState(false); // show message
  const [isLia, setIsLia] = useState(lia); // lia icon only
  const [showNotifContainer, setshowNotifContainer] = useState(false);
  const cookieStatus = getCookie('rcsPsh', '');
  const { isVisible } = useFloatingBox();
  const [isLocation, setIsLocation] = useState(getLocation(props)); // set 'location' for tracker snowplow
  const [floatingBanner, setFloatingBanner] = useState({
    imgLeft:
      'https://images.hukumonline.com/frontend/lt67289e54b250c/lt67289e6330007.png',
    titleMobile: 'Pantau Kewajiban Hukum Perusahaan Anda ',
    titleDesktop: 'Pantau Kewajiban Hukum Perusahaan Anda ',
    titleLink: 'Di sini.',
    targetLink:
      'https://rcs.hukumonline.com/?utm_source=website&utm_medium=sticky_banner',
    targetPage: '_blank',
  });
  // Access GTM variables using window

  useEffect(() => {
    if (!cookieStatus) {
      setshowNotifContainer(true);
    }
  }, []);

  useEffect(() => {
    if (isLocation === '') setIsLocation(getLocation(props));
  }, [isNotif, isLia, isLocation]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isReady) {
        setFloatingBanner({
          imgLeft:
            window.imgLeft ||
            'https://images.hukumonline.com/frontend/lt67289e54b250c/lt67289e6330007.png',
          titleMobile:
            window.titleMobile || 'Pantau Kewajiban Hukum Perusahaan Anda ',
          titleDesktop:
            window.titleDesktop || 'Pantau Kewajiban Hukum Perusahaan Anda ',
          titleLink: window.titleLink || 'Di sini.',
          targetLink:
            window.targetLink ||
            'https://rcs.hukumonline.com/?utm_source=website&utm_medium=sticky_banner',
          targetPage: window.targetPage || '_blank',
        });
        setisReady(true);
      }
    }, 1500); // 2-second delay

    return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
  }, [isReady]);

  const handleChangeBooleanMinimize = () => {
    if (isLocation === '') setIsLocation(getLocation(props));
    if (isLocation !== '') {
      trackerMain({
        snowplow: [
          isLocation,
          `click floating ${
            isNotif ? 'minimize' : 'maximize'
          } - floating banner`,
          '',
          '',
        ],
        ga4: [
          'eventCoreV1BasicData',
          isLocation,
          `floating_banner_${isNotif ? 'min_click' : 'max_click'}`,
          'button',
          `button floating ${isNotif ? 'minimize' : 'maximize'}`,
          '',
          '',
          '',
        ],
      });
    }
    setIsNotif((current) => !current);
    setIsLia((current) => !current);
  };

  const handleChangeBooleanClosed = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 7);
    setCookieWithExpire('rcsPsh', 'exist', tomorrow);
    setIsLia(false);
    if (isLocation === '') setIsLocation(getLocation(props));
    if (isLocation !== '') {
      trackerMain({
        snowplow: [
          isLocation,
          'click floating close - floating banner',
          '',
          '',
        ],
        ga4: [
          'eventCoreV1BasicData',
          isLocation,
          'floating_banner_close_click',
          'button',
          'button floating close',
          '',
          '',
          '',
        ],
      });
    }
  };

  const handleClickLink = (e) => {
    if (isLocation === '') setIsLocation(getLocation(props));
    if (isLocation !== '') {
      trackerMain({
        snowplow: [isLocation, 'click floating link - floating banner', '', ''],
        ga4: [
          'eventCoreV1BasicData',
          isLocation,
          'floating_banner_url_click',
          'button',
          'button floating url ',
          '',
          '',
          '',
        ],
      });
    }
  };

  if (isVisible && isReady && showNotifContainer) {
    return (
      <>
        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
        <div className="d-flex" id="modal-stickyRCS">
          <Collapse isOpen={isLia}>
            <div className="modal-stickyRCS-closed">
              <div className="top row">
                <div className="top3">
                  <img
                    src="/static/images/notif_floating_banner/cross.png"
                    alt="close notif"
                    onClick={handleChangeBooleanClosed}
                    className="hover"
                  />
                </div>
                <div className="top1">
                  <img
                    src={floatingBanner.imgLeft}
                    alt="lia for notif"
                    onClick={handleChangeBooleanMinimize}
                    className="hover"
                  />
                </div>
              </div>
            </div>
          </Collapse>
          <Collapse isOpen={isNotif}>
            <div className="modal-stickyRCS-content">
              <div className="top row">
                <div className="top1">
                  <img src={floatingBanner.imgLeft} alt="banner" />
                </div>
                <div className="top2">
                  <span className="block d-md-none">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: floatingBanner.titleMobile,
                      }}
                    />

                    <a
                      target={floatingBanner.targetPage}
                      onClick={handleClickLink}
                      href={floatingBanner.targetLink}
                      style={{ rel: 'noreferrer' }}
                    >
                      {floatingBanner.titleLink}
                    </a>
                  </span>
                  <span className="d-none d-md-block">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: floatingBanner.titleDesktop,
                      }}
                    />

                    <a
                      target={floatingBanner.targetPage}
                      onClick={handleClickLink}
                      href={floatingBanner.targetLink}
                      style={{
                        textDecoration: 'underline',
                        color: '#0052a3',
                        fontWeight: '700',
                      }}
                      rel="noreferrer"
                    >
                      {floatingBanner.titleLink}
                    </a>
                  </span>
                </div>
                <div className="top3">
                  <button
                    onClick={handleChangeBooleanMinimize}
                    className="btn"
                    type="button"
                    title="close"
                    aria-label="close"
                  >
                    <img
                      alt="close notif"
                      src="/static/images/notif_floating_banner/hide.svg"
                      height="12"
                      width="12"
                      className="cross_ justify-content-end hover"
                    />
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </>
    );
  }
  return null;
};

export default StickyIHC;
