import React, { useEffect, useState } from 'react';
import stylesheet from './floatingBox.scss';
import { useFloatingBox } from './floatingBoxProvider';

const FloatingBox = () => {
  const [isMinimized, setIsMinimized] = useState(false);

  const [isClosed, setIsClosed] = useState(false);
  const { hideFloatingBox, showFloatingBox } = useFloatingBox();
  useEffect(() => {
    const currentHour = new Date().getHours();
    const today = new Date().toLocaleDateString();
    if (new Date(today) > new Date('12/12/2024') || currentHour >= 18) {
      setIsClosed(true);
      showFloatingBox();
    } else {
      hideFloatingBox();
    }
  }, []);
  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      <div
        className={`floating-box ${isClosed ? 'closed' : ''} ${
          isMinimized ? 'minimized' : 'maximized'
        }`}
      >
        <div className="floating-box__content">
          <div
            className={`floating-box__hide ${
              isMinimized ? ' floating-box__border-gold' : 'minimized'
            }`}
          >
            <button
              type="button"
              className="close-button"
              onClick={() => {
                setIsClosed(true);
                showFloatingBox();
              }}
            >
              <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="8" height="8" rx="4" fill="#A8B8C7" />
                <path
                  d="M5.25765 4.99237C5.29287 5.0276 5.31266 5.07537 5.31266 5.12518C5.31266 5.175 5.29287 5.22277 5.25765 5.258C5.22243 5.29322 5.17465 5.31301 5.12484 5.31301C5.07502 5.31301 5.02725 5.29322 4.99202 5.258L3.99999 4.26565L3.00765 5.25768C2.97243 5.29291 2.92465 5.3127 2.87484 5.3127C2.82502 5.3127 2.77725 5.29291 2.74202 5.25768C2.7068 5.22246 2.68701 5.17469 2.68701 5.12487C2.68701 5.07506 2.7068 5.02728 2.74202 4.99206L3.73437 4.00003L2.74234 3.00768C2.70711 2.97246 2.68732 2.92469 2.68732 2.87487C2.68732 2.82506 2.70711 2.77728 2.74234 2.74206C2.77756 2.70683 2.82534 2.68705 2.87515 2.68705C2.92496 2.68705 2.97274 2.70683 3.00796 2.74206L3.99999 3.7344L4.99234 2.7419C5.02756 2.70668 5.07534 2.68689 5.12515 2.68689C5.17496 2.68689 5.22274 2.70668 5.25796 2.7419C5.29319 2.77713 5.31297 2.8249 5.31297 2.87471C5.31297 2.92453 5.29319 2.9723 5.25796 3.00753L4.26562 4.00003L5.25765 4.99237Z"
                  fill="white"
                />
              </svg>
            </button>
            <button
              type="button"
              onClick={() => {
                setIsMinimized(false);
              }}
            >
              <img
                src="https://static.hukumonline.com/assets/images/other/probono_award_icon.png"
                alt="close"
                className="img-fluid icon_probono"
              />
            </button>
          </div>

          <div
            className={`floating-box__show  ${isMinimized ? 'minimized' : ''}`}
          >
            <div className="video-container">
              <button
                type="button"
                onClick={() => {
                  setIsMinimized(true);
                }}
                className="mb-1 d-flex justify-content-end align-items-center"
              >
                <span className="badge badge-pill minimize-container d-inline-flex align-items-center">
                  Minimize
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-1"
                  >
                    <path
                      d="M7 1.3125C5.87512 1.3125 4.7755 1.64607 3.8402 2.27102C2.90489 2.89597 2.17591 3.78423 1.74544 4.82349C1.31496 5.86274 1.20233 7.00631 1.42179 8.10958C1.64124 9.21284 2.18292 10.2263 2.97833 11.0217C3.77374 11.8171 4.78716 12.3588 5.89043 12.5782C6.99369 12.7977 8.13726 12.685 9.17651 12.2546C10.2158 11.8241 11.104 11.0951 11.729 10.1598C12.3539 9.2245 12.6875 8.12488 12.6875 7C12.6859 5.49207 12.0862 4.04636 11.0199 2.98009C9.95365 1.91382 8.50793 1.31409 7 1.3125ZM9.1875 7.4375H4.8125C4.69647 7.4375 4.58519 7.39141 4.50314 7.30936C4.4211 7.22731 4.375 7.11603 4.375 7C4.375 6.88397 4.4211 6.77269 4.50314 6.69064C4.58519 6.60859 4.69647 6.5625 4.8125 6.5625H9.1875C9.30353 6.5625 9.41481 6.60859 9.49686 6.69064C9.57891 6.77269 9.625 6.88397 9.625 7C9.625 7.11603 9.57891 7.22731 9.49686 7.30936C9.41481 7.39141 9.30353 7.4375 9.1875 7.4375Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </button>
              <iframe
                src="https://www.youtube.com/embed/otcDtiMpoOo?si=88nBi0bRLBP-ffOg"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
              <a
                href="https://awards.hukumonline.com/probono-2024"
                className="btn button-selengkapnya rounded-0 btn-block "
              >
                <span className="gradient-text">Lihat Selengkapnya </span>
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.2845 9.59689L11.222 14.6594C11.0634 14.8179 10.8485 14.907 10.6243 14.907C10.4001 14.907 10.1851 14.8179 10.0266 14.6594C9.86813 14.5009 9.77908 14.2859 9.77908 14.0617C9.77908 13.8376 9.86813 13.6226 10.0266 13.4641L13.6484 9.84369H3.3125C3.08872 9.84369 2.87411 9.7548 2.71588 9.59656C2.55764 9.43833 2.46875 9.22372 2.46875 8.99994C2.46875 8.77616 2.55764 8.56155 2.71588 8.40332C2.87411 8.24508 3.08872 8.15619 3.3125 8.15619H13.6484L10.028 4.53369C9.86954 4.37518 9.78049 4.1602 9.78049 3.93603C9.78049 3.71187 9.86954 3.49689 10.028 3.33838C10.1866 3.17987 10.4015 3.09082 10.6257 3.09082C10.8499 3.09082 11.0649 3.17987 11.2234 3.33838L16.2859 8.40088C16.3645 8.47937 16.4269 8.57263 16.4695 8.67531C16.512 8.77798 16.5338 8.88804 16.5337 8.99918C16.5335 9.11031 16.5115 9.22032 16.4687 9.3229C16.4259 9.42547 16.3633 9.51859 16.2845 9.59689Z"
                    fill="url(#paint0_linear_4635_4648)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_4635_4648"
                      x1="9.50121"
                      y1="3.09082"
                      x2="9.50121"
                      y2="14.907"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FFD31F" />
                      <stop offset="1" stopColor="#612F0C" />
                    </linearGradient>
                  </defs>
                </svg>
              </a>
            </div>
            <div />
          </div>
        </div>
      </div>
    </>
  );
};
export default FloatingBox;
