import React from 'react';
import { Button } from 'reactstrap';
import { trackerMain } from '../../libraries/tracker';

const HolproSection = ({ order }) => (
  <div className="holpro-section">
    <div className="benefit-img">
      <img src="https://images.hukumonline.com/website-assets/homepage/benefit-holpro-new.webp" width={387} alt="holpro-feature" />
    </div>
    <div className="label">
      Hukumonline Pro Intelligence
    </div>
    <h3>AI-Powered. Trustworthy. Prominent Networking</h3>
    <p className="subtitle">Platform AI hukum terpercaya sebagai solusi komprehensif Anda.</p>
    <div className="features">
      <p>Mulai berlangganan dan dapatkan:</p>
      <ul>
        <li>Tanya Jawab Hukum 24/7 Berbasis AI</li>
        <li>Analisis Hukum Dua Bahasa Langsung ke Email Anda</li>
        <li>Akses ke Komunitas Hukum Terbesar di Tanah Air</li>
      </ul>
    </div>
    <Button
      href="https://pro.hukumonline.com/paket"
      className="btn-holpro-section"
      onClick={() => trackerMain({
        snowplow: [
          'HOL - New Homepage',
          'click button - hol pro cta middle',
          '',
          '',
          '',
        ],
        ga4: [
          'eventCoreV1BasicData',
          'New - New Homepage',
          `section_subscribe_hol_pro_${order}`,
          'button_click',
          `button berlangganan sekarang hol pro ${order}`,
          '',
          '',
          '',
        ],
      })
      }
    >
      Berlangganan Sekarang{' '}
      <i className="fa fa-chevron-right ml-2" />
    </Button>
  </div>
);

export default HolproSection;
