import React from 'react';
import Row from 'reactstrap/lib/Row';
import { BsHouseDoorFill } from 'react-icons/bs';
import { Badge } from 'reactstrap';
import { pushGa4Generic, structuredEvent } from '../../../libraries/tracker';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL, PRO_URL } = publicRuntimeConfig;

const DesktopPro = ({ t }) => (
  <>
    <div className="navbar-list-wrapper">
      <div className="navbar-list">
        <div>
          <a
            onClick={() => {
              structuredEvent(
                'navbar',
                'click url - legal_analysis_home',
                'beranda',
                '',
              );
              pushGa4Generic({
                event: 'Navbar',
                action: 'click url',
                component: 'legal_analysis_home',
              });
            }}
            // https://pro.hukumonline.com/home/?utm_source=website&utm_medium=header
            href={`${PRO_URL}/home/?utm_source=website&utm_medium=header`}
          >
            <div className="">
              <div className=" mr-1 d-flex align-items-center">
                <img
                  src={`${BASE_URL}/static/images/menu/analysis.svg`}
                  alt="Icon analysis"
                  className="mr-2"
                />
                <span className="body text-primary font-weight-semibold">
                  Analisis Hukum
                </span>
              </div>
              <div className="d-flex flex-column desc-menu">
                <small className="small font-weight-medium text-main">
                  {t('desc-menu-analisa-hukum')}
                </small>
              </div>
            </div>
          </a>
        </div>
        <div>
          <div className="d-flex flex-column">
            <a
              onClick={() => {
                structuredEvent(
                  'navbar',
                  'click url - pusat_data_home',
                  'beranda',
                  '',
                );
                pushGa4Generic({
                  event: 'Navbar',
                  action: 'click url',
                  component: 'pusat_data_home',
                });
              }}
              href={`${BASE_URL}/pusatdata/`}
              className=""
            >
              <div className=" mr-1 d-flex align-items-center">
                <img
                  src={`${BASE_URL}/static/images/menu/pusatdata.svg`}
                  alt="Icon analysis"
                  className="mr-2"
                />
                <span className="body text-primary font-weight-semibold">
                  Pusat Data
                </span>
              </div>

              <div className="d-flex flex-column desc-menu">
                <small className="small font-weight-medium text-main">
                  {t('desc-menu-pusat-data')}
                </small>
              </div>
            </a>
          </div>
          <div className="row">
            <div className="col-6">
              <a
                onClick={() => {
                  structuredEvent(
                    'navbar',
                    'click url - pusat_data_peraturan_pusat',
                    'beranda',
                    '',
                  );
                  pushGa4Generic({
                    event: 'Navbar',
                    action: 'click url',
                    component: 'pusat_data_peraturan_pusat',
                  });
                }}
                href={`${BASE_URL}/pusatdata/peraturan-pusat/`}
                className="py-1"
              >
                <span className="text-main small font-weight-semibold">
                  Peraturan Pusat
                </span>
              </a>
            </div>
            <div className="col-6">
              <a
                onClick={() => {
                  structuredEvent(
                    'navbar',
                    'click url - pusat_data_putusan',
                    'beranda',
                    '',
                  );
                  pushGa4Generic({
                    event: 'Navbar',
                    action: 'click url',
                    component: 'pusat_data_putusan',
                  });
                }}
                href={`${BASE_URL}/pusatdata/putusan/`}
                className="py-1"
              >
                <span className="text-main small font-weight-semibold">
                  Putusan
                </span>
              </a>
            </div>
            <div className="col-6">
              <a
                onClick={() => {
                  structuredEvent(
                    'navbar',
                    'click url - pusat_data_peraturan_daerah',
                    'beranda',
                    '',
                  );
                  pushGa4Generic({
                    event: 'Navbar',
                    action: 'click url',
                    component: 'pusat_data_peraturan_daerah',
                  });
                }}
                href={`${BASE_URL}/pusatdata/peraturan-daerah/`}
                className="py-1"
              >
                <span className="text-main small font-weight-semibold">
                  Peraturan Daerah
                </span>
              </a>
            </div>
            <div className="col-6">
              <a
                onClick={() => {
                  structuredEvent(
                    'navbar',
                    'click url - pusat_data_precedent',
                    'beranda',
                    '',
                  );
                  pushGa4Generic({
                    event: 'Navbar',
                    action: 'click url',
                    component: 'pusat_data_precedent',
                  });
                }}
                href={`${BASE_URL}/pusatdata/precedent/`}
                className="py-1"
              >
                <span className="text-main small font-weight-semibold">
                  Precedent
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <img
            src={`${BASE_URL}/static/images/main/ask.svg`}
            alt="Icon Ask"
            className="mr-2"
          />
          <span className="small">
            Ada Pertanyaan?{' '}
            <a
              href="https://www.hukumonline.com/produk/hubungi-kami/id/"
              className="font-weight-semibold text-primary"
            >
              {' '}
              Hubungi Kami{' '}
            </a>
          </span>
        </div>
      </div>
      <div className="navbar-list">
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - legal_intellegence_update',
              'beranda',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'click url',
              component: 'legal_intellegence_update',
            });
          }}
          href={`${PRO_URL}/legal-intelligence`}
        >
          <div className="d-flex flex-column">
            <div className=" mr-1 d-flex align-items-center">
              <img
                src={`${BASE_URL}/static/images/menu/intelligence.svg`}
                alt="Icon intelligence"
                className="mr-2 "
                style={{ height: '20px' }}
              />
              <span className="body text-primary font-weight-semibold">
                Legal Intelligence Updates
              </span>
            </div>
            <div className="d-flex flex-column desc-menu">
              <small className="small font-weight-medium text-main">
                {t('desc-menu-legal-intelligence')}
              </small>
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - premium_stories_home',
              'beranda',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'click url',
              component: 'premium_stories_home',
            });
          }}
          href={`${BASE_URL}/stories/`}
        >
          <div className="d-flex flex-column">
            <div className=" mr-1 d-flex align-items-center">
              <img
                src={`${BASE_URL}/static/images/menu/premiumstories.svg`}
                alt="Icon Premium Stories"
                className="mr-2"
              />
              <span className="body text-primary font-weight-semibold">
                Premium Stories
              </span>
            </div>
            <div className="d-flex flex-column desc-menu">
              <small className="small font-weight-medium text-main">
                {t('desc-menu-premium-stories')}
              </small>
            </div>
          </div>
        </a>
      </div>
      <div className="">
        <span className="small font-weight-semibold text-main">
          PRODUK PILIHAN
        </span>
        <div className="bg-hol-soft d-flex flex-column my-2 py-2">
          <a
            onClick={() => {
              structuredEvent(
                'navbar',
                'click url - pro_produk_pilihan_1',
                'beranda',
                '',
              );
              pushGa4Generic({
                event: 'Navbar',
                action: 'click url',
                component: 'pro_produk_pilihan_1',
              });
            }}
            href="https://exdoma.hukumonline.com/?utm_source=website&utm_medium=header&utm_campaign=produk_pilihan"
          >
            <div className="d-flex flex-row">
              <div className="px-2">
                <img
                  src={`${BASE_URL}/static/images/menu/exdoma50.png`}
                  alt="Icon Exdoma"
                />
              </div>
              <div className="d-flex flex-column justify-content-center">
                <span className="extra-small font-weight-semibold text-main">
                  Exdoma
                </span>
                <p className="micro text-main m-0">{t('desc-menu-exdoma')}</p>
              </div>
            </div>
          </a>
        </div>
        <div className="bg-hol-soft d-flex flex-column my-2 py-2">
          <a
            onClick={() => {
              structuredEvent(
                'navbar',
                'click url - pro_produk_pilihan_2',
                'beranda',
                '',
              );
              pushGa4Generic({
                event: 'Navbar',
                action: 'click url',
                component: 'pro_produk_pilihan_2',
              });
            }}
            href={`${BASE_URL}/online-publication/?utm_source=website&utm_medium=header&utm_campaign=produk_pilihan`}
          >
            <div className="d-flex flex-row">
              <div className="px-2">
                <img
                  src={`${BASE_URL}/static/images/menu/onlinepub-pilihan50.png`}
                  alt=""
                />
              </div>
              <div className="d-flex flex-column justify-content-center">
                <span className="extra-small font-weight-semibold text-main">
                  Publikasi Online
                </span>
                <p className="micro text-main m-0">
                  {t('desc-menu-publikasi-online')}
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="bg-hol-soft d-flex flex-column my-2 py-2">
          <a
            onClick={() => {
              structuredEvent(
                'navbar',
                'click url - pro_produk_pilihan_3',
                'beranda',
                '',
              );
              pushGa4Generic({
                event: 'Navbar',
                action: 'click url',
                component: 'pro_produk_pilihan_3',
              });
            }}
            href="https://awards.hukumonline.com/?utm_source=website&utm_medium=header&utm_campaign=produk_pilihan"
          >
            <div className="d-flex flex-row">
              <div className="px-2">
                <img
                  src={`${BASE_URL}/static/images/menu/ranking-pilihan50.png`}
                  alt="Icon Ranking"
                />
              </div>
              <div className="d-flex flex-column justify-content-center">
                <span className="extra-small font-weight-semibold text-main">
                  Rankings
                </span>
                <p className="micro text-main m-0">{t('desc-menu-ranking')}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </>
);
// DesktopPro.propTypes = {
//   setMenu: PropTypes.func.isRequired,
//   toggleMenu: PropTypes.func.isRequired,
//   data: PropTypes.shape.isRequired,
// };
export default DesktopPro;
