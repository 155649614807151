import React from 'react';
import { Badge } from 'reactstrap';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL, JURNAL_URL, OC_URL, PRO_URL } = publicRuntimeConfig;

const EventDesktop = ({ t }) => {
  const utm = '?utm_source=website&utm_medium=header';
  return (
    <>
      <div className="navbar-list-wrapper">
        <div className="navbar-list">
          <div className="">
            <div className="d-flex flex-column py-2 px-2">
              <a
                onClick={() => {
                  structuredEvent(
                    'navbar',
                    'click url - event_home',
                    'beranda',
                    '',
                    '',
                  );
                  pushGa4Generic({
                    event: 'Navbar',
                    action: 'link_click',
                    component: 'event_home',
                  });
                }}
                href={`${BASE_URL}/events/`}
                className="py-1"
              >
                <div className=" mr-1 d-flex align-items-center">
                  <img
                    src={`${BASE_URL}/static/images/menu/event.svg`}
                    alt="Icon Event"
                    className="mr-2"
                  />
                  <span className="body text-primary font-weight-semibold">
                    Event
                  </span>
                </div>

                <div className="d-flex flex-column desc-menu">
                  <small className="small font-weight-medium text-main">
                    {t('desc-menu-event')}
                  </small>
                </div>
              </a>
            </div>
            <div className="pl-2 d-flex flex-column mr-5 pr-3">
              <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-column">
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - event_kegiatan_terkini',
                        'kegiatan terkini',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'link_click',
                        component: 'event_kegiatan_terkini',
                      });
                    }}
                    href={`${BASE_URL}/events/kegiatanterkini/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Kegiatan Terkini
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - event_event_organizer',
                        'event organizer',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'link_click',
                        component: 'event_event_organizer',
                      });
                    }}
                    href={`${BASE_URL}/events/eo/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Event Organizer
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - event_iht',
                        'iht',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'link_click',
                        component: 'event - iht',
                      });
                    }}
                    href={`${BASE_URL}/events/iht/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      In-House Training
                    </span>
                  </a>
                </div>
                <div className="d-flex flex-column flex-shrink-1">
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - event_arsip_kegiatan',
                        'arsip kegiatan',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'link_click',
                        component: 'event_arsip_kegiatan',
                      });
                    }}
                    href={`${BASE_URL}/events/arsipkegiatan/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Arsip Kegiatan
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - event_narasumber',
                        'narasumber',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'link_click',
                        component: 'event_narasumber',
                      });
                    }}
                    href={`${BASE_URL}/events/narasumber/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Narasumber
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column py-2 px-2">
            <a
              onClick={() => {
                structuredEvent(
                  'navbar',
                  'click url - ranking_home',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'Navbar',
                  action: 'link_click',
                  component: 'ranking_home',
                });
              }}
              href={`https://awards.hukumonline.com/${utm}`}
              className="py-1"
            >
              <div className=" mr-1 d-flex align-items-center">
                <img
                  src={`${BASE_URL}/static/images/menu/ranking.svg`}
                  alt="Icon Ranking"
                  className="mr-2"
                />
                <span className="body text-primary font-weight-semibold">
                  Awards
                </span>
              </div>

              <div className="d-flex flex-column desc-menu">
                <small className="small font-weight-medium text-main">
                  {t('desc-menu-ranking')}
                </small>
              </div>
            </a>
          </div>

          <div className="flex-grow-1 d-flex align-items-end mt-5 ">
            <img
              src={`${BASE_URL}/static/images/main/ask.svg`}
              alt="Icon Ask"
              className="mr-2"
            />
            <span className="small">
              Ada Pertanyaan?{' '}
              <a
                href="https://www.hukumonline.com/produk/hubungi-kami/id/"
                className="font-weight-semibold text-primary"
              >
                {' '}
                Hubungi Kami{' '}
              </a>
            </span>
          </div>
        </div>
        <div className="navbar-list">
          <a
            onClick={() => {
              structuredEvent(
                'navbar',
                'click url - online_publication_home',
                'beranda',
                ' ',
                '',
              );
              pushGa4Generic({
                event: 'Navbar',
                action: 'link_click',
                component: 'online_publication_home',
              });
            }}
            href={`${BASE_URL}/online-publication/`}
          >
            <div className=" mr-1 d-flex align-items-center">
              <img
                src={`${BASE_URL}/static/images/menu/onlinepublication.svg`}
                alt="Icon online publication"
                className="mr-2"
              />
              <span className="body text-primary font-weight-semibold">
                Publikasi Online
              </span>
            </div>
            <div className="d-flex flex-column desc-menu">
              <small className="small font-weight-medium text-main">
                {t('desc-menu-publikasi-online')}
              </small>
            </div>
          </a>
          <a href={`${OC_URL}/?utm_source=website&utm_medium=katalog_produk`}>
            <div className=" mr-1 d-flex align-items-center">
              <img
                src={`${BASE_URL}/static/images/menu/onlinecourse.svg`}
                alt="Icon analysis"
                className="mr-2"
              />
              <span className="body text-primary font-weight-semibold">
                Online Course
              </span>
            </div>
            <div className="d-flex flex-column desc-menu">
              <small className="small font-weight-medium text-main">
                {t('desc-menu-OC')}
              </small>
            </div>
          </a>
          <a
            onClick={() => {
              structuredEvent(
                'navbar',
                'click url - pkpa-home',
                'beranda',
                '',
                '',
              );
              pushGa4Generic({
                event: 'Navbar',
                action: 'link_click',
                component: 'pkpa-home',
              });
            }}
            href={`https://pkpa.hukumonline.com/${utm}`}
          >
            <div className=" mr-1 d-flex align-items-center">
              <img
                src={`${BASE_URL}/static/images/menu/pkpa.svg`}
                alt="Icon PKPA"
                className="mr-2"
              />
              <span className="body text-primary font-weight-semibold">
                PKPA
              </span>
            </div>
            <div className="d-flex flex-column desc-menu">
              <small className="small font-weight-medium text-main">
                {t('desc-menu-PKPA')}
              </small>
            </div>
          </a>
        </div>
        <div className="">
          <div className="">
            <span className="small font-weight-semibold text-main">
              PRODUK PILIHAN
            </span>
            <div className="d-flex flex-column  my-2 py-2">
              <a href="https://awards.hukumonline.com/hall-of-fame-2024">
                <div className="d-flex flex-row">
                  <div>
                    <img
                      src={`${BASE_URL}/static/images/menu/HOF-2024.png`}
                      alt="Icon Practice Leader 2024"
                    />
                  </div>
                  <div className="bg-hol-soft pl-2 d-flex flex-column justify-content-center w-100">
                    <div className="d-flex flex-row">
                      <span className="extra-small font-weight-semibold text-main">
                        Hall of Fame
                      </span>
                    </div>

                    {/* <p className="micro text-main m-0">Coming Soon</p> */}
                  </div>
                </div>
              </a>
            </div>
            <div className="d-flex flex-column  my-2 py-2">
              <a href={`${BASE_URL}/practice-leaders`}>
                <div className="d-flex flex-row">
                  <div>
                    <img
                      src={`${BASE_URL}/static/images/menu/pl-2024.png`}
                      alt="Icon Practice Leader 2024"
                    />
                  </div>
                  <div className="bg-hol-soft pl-2 d-flex flex-column justify-content-center w-100">
                    <div className="d-flex flex-row">
                      <span className="extra-small font-weight-semibold text-main">
                        Hukumonline Practice Leaders
                      </span>
                    </div>

                    {/* <p className="micro text-main m-0">Coming Soon</p> */}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// EventDesktop.propTypes = {
//   setMenu: PropTypes.func.isRequired,
//   toggleMenu: PropTypes.func.isRequired,
//   data: PropTypes.shape.isRequired,
// };
export default EventDesktop;
