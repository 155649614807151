import React from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

export const LanguageDropdown = (props) => {
  const { t, i18n } = props;
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="position-relative lang-dropdown ml-2"
    >
      <DropdownToggle
        caret
        className="text-uppercase text-white font-weight-medium bg-transparent border-0 d-flex align-items-center"
      >
        <span className="mr-1 active-lang">{i18n.language}</span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => handleLanguageChange('id')}
          className={`option-lang ${i18n.language === 'id' ? 'selected' : ''}`}
        >
          ID - Indonesia
        </DropdownItem>
        <DropdownItem
          onClick={() => handleLanguageChange('en')}
          className={`option-lang ${i18n.language === 'en' ? 'selected' : ''}`}
        >
          EN - English
        </DropdownItem>
        {/* Add more languages as needed */}
      </DropdownMenu>
    </Dropdown>
  );
};
