import React from 'react';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL, PRO_URL, JURNAL_URL, OC_URL } = publicRuntimeConfig;

const menuList = {
  pro: {
    mainTitle: 'Pro',
    link: `${PRO_URL}/home/?utm_source=website&utm_medium=header`,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#F8CA0F"
        viewBox="0 0 256 256"
        className="mr-1"
      >
        <path d="M234.5,114.38l-45.1,39.36,13.51,58.6a16,16,0,0,1-23.84,17.34l-51.11-31-51,31a16,16,0,0,1-23.84-17.34L66.61,153.8,21.5,114.38a16,16,0,0,1,9.11-28.06l59.46-5.15,23.21-55.36a15.95,15.95,0,0,1,29.44,0h0L166,81.17l59.44,5.15a16,16,0,0,1,9.11,28.06Z" />
      </svg>
    ),
    tracker: {
      snowPlow: {
        event: 'navbar',
        action: 'click url - m_hol_pro',
        action_v: 'click url - m_hamburger_pro',
        component: 'beranda',
      },
      ga4: {
        event: 'navbar',
        action: 'click url',
        component: 'm_hol_pro',
        component_v: 'm_hamburger_pro',
      },
    },

    iconDark: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="curentColor"
        viewBox="0 0 256 256"
        className="mr-1"
      >
        <path d="M239.2,97.29a16,16,0,0,0-13.81-11L166,81.17,142.72,25.81h0a15.95,15.95,0,0,0-29.44,0L90.07,81.17,30.61,86.32a16,16,0,0,0-9.11,28.06L66.61,153.8,53.09,212.34a16,16,0,0,0,23.84,17.34l51-31,51.11,31a16,16,0,0,0,23.84-17.34l-13.51-58.6,45.1-39.36A16,16,0,0,0,239.2,97.29Zm-15.22,5-45.1,39.36a16,16,0,0,0-5.08,15.71L187.35,216v0l-51.07-31a15.9,15.9,0,0,0-16.54,0l-51,31h0L82.2,157.4a16,16,0,0,0-5.08-15.71L32,102.35a.37.37,0,0,1,0-.09l59.44-5.14a16,16,0,0,0,13.35-9.75L128,32.08l23.2,55.29a16,16,0,0,0,13.35,9.75L224,102.26S224,102.32,224,102.33Z" />
      </svg>
    ),
    subMenu: [
      {
        menu: 'Analisis Hukum',
        tracker: {
          snowPlow: {
            event: 'navbar',
            action: 'click url - legal_analysis_home',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'legal_analysis_home',
          },
        },
        link: PRO_URL,
      },
      {
        menu: 'Legal Intelligence Updates',
        tracker: {
          snowPlow: {
            event: 'navbar',
            action: 'click url - legal_intellegence_update',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'legal_intellegence_update',
          },
        },
        link: `${PRO_URL}/legal-intelligence`,
      },
      {
        menu: 'Pusat Data',
        tracker: {
          snowPlow: {
            event: 'navbar',
            action: 'click url - pusat_data_home',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'pusat_data_home',
          },
        },
        link: `${BASE_URL}/pusatdata/`,
      },
      {
        menu: 'Premium Stories',
        tracker: {
          snowPlow: {
            event: 'navbar',
            action: 'click url - premium_stories_home',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'premium_stories_home',
          },
        },
        link: `${BASE_URL}/stories/`,
      },
    ],
  },
  solusi: {
    mainTitle: 'Solusi',
    link: `${BASE_URL}/solusi/`,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#ffffff"
        viewBox="0 0 256 256"
        className="mr-1"
      >
        <path d="M180,232a12,12,0,0,1-12,12H88a12,12,0,0,1,0-24h80A12,12,0,0,1,180,232Zm40-128a91.51,91.51,0,0,1-35.17,72.35A12.26,12.26,0,0,0,180,186v2a20,20,0,0,1-20,20H96a20,20,0,0,1-20-20v-2a12,12,0,0,0-4.7-9.51A91.57,91.57,0,0,1,36,104.52C35.73,54.69,76,13.2,125.79,12A92,92,0,0,1,220,104Zm-24,0a68,68,0,0,0-69.65-68C89.56,36.88,59.8,67.55,60,104.38a67.71,67.71,0,0,0,26.1,53.19A35.87,35.87,0,0,1,100,184h56.1A36.13,36.13,0,0,1,170,157.49,67.68,67.68,0,0,0,196,104Zm-20.07-5.32a48.5,48.5,0,0,0-31.91-40,12,12,0,0,0-8,22.62,24.31,24.31,0,0,1,16.09,20,12,12,0,0,0,23.86-2.64Z" />
      </svg>
    ),
    iconDark: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 256 256"
        className="mr-1"
      >
        <path d="M180,232a12,12,0,0,1-12,12H88a12,12,0,0,1,0-24h80A12,12,0,0,1,180,232Zm40-128a91.51,91.51,0,0,1-35.17,72.35A12.26,12.26,0,0,0,180,186v2a20,20,0,0,1-20,20H96a20,20,0,0,1-20-20v-2a12,12,0,0,0-4.7-9.51A91.57,91.57,0,0,1,36,104.52C35.73,54.69,76,13.2,125.79,12A92,92,0,0,1,220,104Zm-24,0a68,68,0,0,0-69.65-68C89.56,36.88,59.8,67.55,60,104.38a67.71,67.71,0,0,0,26.1,53.19A35.87,35.87,0,0,1,100,184h56.1A36.13,36.13,0,0,1,170,157.49,67.68,67.68,0,0,0,196,104Zm-20.07-5.32a48.5,48.5,0,0,0-31.91-40,12,12,0,0,0-8,22.62,24.31,24.31,0,0,1,16.09,20,12,12,0,0,0,23.86-2.64Z" />
      </svg>
    ),

    tracker: {
      snowPlow: {
        event: 'navbar',
        action: 'click url - m_hol_solusi',
        action_v: 'click url - m_hamburger_solusi',
        component: 'beranda',
      },
      ga4: {
        event: 'navbar',
        action: 'click url',
        component: 'm_hol_solusi',
        component_v: 'm_hamburger_solusi',
      },
    },
    subMenu: [
      {
        menu: 'Home',
        tracker: {
          snowPlow: {
            event: 'navbar',
            action: 'click url - solusi_home',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'solusi_home',
          },
        },
        link: `${BASE_URL}/solusi/`,
      },
      {
        menu: 'University Solutions',
        tracker: {
          snowPlow: {
            event: 'navbar',
            action: 'click url - unisol',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'unisol',
          },
        },
        link: `${PRO_URL}/solusi-universitas-hukum/?utm_source=website&utm_medium=navbar`,
      },
      {
        menu: 'Regulatory Compliance System',
        tracker: {
          snowPlow: {
            event: 'navbar',
            action: 'click url - rcs_home',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'rcs_home',
          },
        },
        link:
          'https://rcs.hukumonline.com/?utm_source=website&utm_medium=header',
      },
      {
        menu: 'Document Management System',
        tracker: {
          snowPlow: {
            event: 'navbar',
            action: 'click url - exdoma_home',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'exdoma_home',
          },
        },
        link:
          'https://exdoma.hukumonline.com/?utm_source=website&utm_medium=header',
      },
      {
        menu: 'Izin Usaha',
        tracker: {
          snowPlow: {
            event: 'navbar',
            action: 'click url - easybiz_home',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'easybiz_home',
          },
        },
        link:
          'https://www.easybiz.id/#layanan-dan-harga?utm_source=hukumonline&utm_medium=navbar',
      },
      {
        menu: 'Konsultasi Hukum',
        tracker: {
          snowPlow: {
            event: 'navbar',
            action: 'click url - justika_home',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'justika_home',
          },
        },
        link:
          'https://www.justika.com/partner/klinik/?utm_source=hukumonline&utm_medium=navbar&utm_campaign=chat',
      },

      {
        menu: 'Pembuatan Dokumen',
        tracker: {
          snowPlow: {
            event: 'navbar',
            action: 'click url - pembuatan_dokumen_home',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'pembuatan_dokumen_home',
          },
        },
        link:
          'https://business.justika.com/template.html/?utm_source=hukumonline&utm_medium=navbar&utm_campaign=template',
      },

      {
        menu: 'Hukumonline 360',
        tracker: {
          snowPlow: {
            event: 'navbar',
            action: 'click url - hukumonline_360',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'hukumonline_360',
          },
        },
        link: `${BASE_URL}/hukumonline-360/`,
      },
    ],
  },
  wawasan: {
    mainTitle: 'Info Hukum',
    link: `${BASE_URL}/klinik/`,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#ffffff"
        viewBox="0 0 256 256"
        className="mr-1"
      >
        <path d="M224,48H160a40,40,0,0,0-32,16A40,40,0,0,0,96,48H32A16,16,0,0,0,16,64V192a16,16,0,0,0,16,16H96a24,24,0,0,1,24,24,8,8,0,0,0,16,0,24,24,0,0,1,24-24h64a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM96,192H32V64H96a24,24,0,0,1,24,24V200A39.81,39.81,0,0,0,96,192Zm128,0H160a39.81,39.81,0,0,0-24,8V88a24,24,0,0,1,24-24h64ZM160,88h40a8,8,0,0,1,0,16H160a8,8,0,0,1,0-16Zm48,40a8,8,0,0,1-8,8H160a8,8,0,0,1,0-16h40A8,8,0,0,1,208,128Zm0,32a8,8,0,0,1-8,8H160a8,8,0,0,1,0-16h40A8,8,0,0,1,208,160Z" />
      </svg>
    ),
    iconDark: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="currentColor"
        viewBox="0 0 256 256"
        className="mr-1"
      >
        <path d="M224,48H160a40,40,0,0,0-32,16A40,40,0,0,0,96,48H32A16,16,0,0,0,16,64V192a16,16,0,0,0,16,16H96a24,24,0,0,1,24,24,8,8,0,0,0,16,0,24,24,0,0,1,24-24h64a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM96,192H32V64H96a24,24,0,0,1,24,24V200A39.81,39.81,0,0,0,96,192Zm128,0H160a39.81,39.81,0,0,0-24,8V88a24,24,0,0,1,24-24h64ZM160,88h40a8,8,0,0,1,0,16H160a8,8,0,0,1,0-16Zm48,40a8,8,0,0,1-8,8H160a8,8,0,0,1,0-16h40A8,8,0,0,1,208,128Zm0,32a8,8,0,0,1-8,8H160a8,8,0,0,1,0-16h40A8,8,0,0,1,208,160Z" />
      </svg>
    ),
    tracker: {
      snowPlow: {
        event: 'navbar',
        action: 'click url - m_hol_info_hukum',
        action_v: 'click url - m_hamburger_info_hukum',
        component: 'beranda',
      },
      ga4: {
        event: 'navbar',
        action: 'click url',
        component: 'm_hol_info_hukum',
        component_v: 'm_hamburger_info_hukum',
      },
    },
    subMenu: [
      {
        menu: 'Klinik',
        tracker: {
          snowPlow: {
            event: 'navbar',
            action: 'click url - klinik_home',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'klinik_home',
          },
        },
        link: `${BASE_URL}/klinik/`,
        subMenu: [
          {
            menu: 'Home',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_home',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_home',
              },
            },
            link: `${BASE_URL}/klinik/`,
          },
          {
            menu: 'Tips Hukum',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_tips_hukum',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_tips_hukum',
              },
            },
            link: `${BASE_URL}/klinik/tips/`,
          },
          {
            menu: 'Pidana',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_pidana',
                component: 'pidana',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_pidana',
              },
            },
            link: `${BASE_URL}/klinik/pidana/`,
          },
          {
            menu: 'Keluarga',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_keluarga',
                component: 'keluarga',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_keluarga',
              },
            },
            link: `${BASE_URL}/klinik/keluarga/`,
          },
          {
            menu: 'Perdata',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_perdata',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_perdata',
              },
            },
            link: `${BASE_URL}/klinik/perdata/`,
          },
          {
            menu: 'Kenegaraan',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_kenegaraan',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_kenegaraan',
              },
            },
            link: `${BASE_URL}/klinik/kenegaraan/`,
          },
          {
            menu: 'Profesi Hukum',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_profesi_hukum',
                component: 'profesi hukum',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_profesi_hukum',
              },
            },
            link: `${BASE_URL}/klinik/profesi-hukum/`,
          },
          {
            menu: 'Ilmu Hukum',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_ilmu_hukum',
                component: 'ilmu hukum',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_ilmu_hukum',
              },
            },
            link: `${BASE_URL}/klinik/ilmu-hukum/`,
          },
          {
            menu: 'Bisnis',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_bisnis',
                component: 'bisnis',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_bisnis',
              },
            },
            link: `${BASE_URL}/klinik/bisnis/`,
          },
          {
            menu: 'Pertanahan & Properti',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_pertanahan_properti',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_pertanahan_properti',
              },
            },
            link: `${BASE_URL}/klinik/pertanahan-properti/`,
          },
          {
            menu: 'Ketenagakerjaan',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_ketenagakerjaan',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_ketenagakerjaan',
              },
            },
            link: `${BASE_URL}/klinik/ketenagakerjaan/`,
          },
          {
            menu: 'Perlindungan Konsumen',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_perlindungan_konsumen',
                component: 'perlindungan konsumen',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_perlindungan_konsumen',
              },
            },
            link: `${BASE_URL}/klinik/perlindungan-konsumen/`,
          },
          {
            menu: 'Hak Asasi Manusia',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_hak_asasi_manusia',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_hak_asasi_manusia',
              },
            },
            link: `${BASE_URL}/klinik/hak-asasi-manusia/`,
          },
          {
            menu: 'Kekayaan Intelektual',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_kekayaan_intelektual',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_kekayaan_intelektual',
              },
            },
            link: `${BASE_URL}/klinik/kekayaan-intelektual/`,
          },
          {
            menu: 'Teknologi',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_teknologi',
                component: 'teknologi',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_teknologi',
              },
            },
            link: `${BASE_URL}/klinik/teknologi/`,
          },
          {
            menu: 'Startup & UMKM',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_startup_umkm',
                component: 'startup & umkm',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_startup_umkm',
              },
            },
            link: `${BASE_URL}/klinik/start-up-umkm/`,
          },
          {
            menu: 'Olahraga',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - klinik_olahraga',
                component: 'olahraga',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'klinik_olahraga',
              },
            },
            link: `${BASE_URL}/klinik/olahraga/`,
          },
        ],
      },
      {
        menu: 'Berita',
        tracker: {
          snowPlow: {
            event: 'Navbar',
            action: 'click url - berita_home',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'berita_home',
          },
        },
        link: `${BASE_URL}/berita/`,
        subMenu: [
          {
            menu: 'Home',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'berita_home',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'berita_home',
              },
            },
            link: `${BASE_URL}/berita/`,
          },
          {
            menu: 'Utama',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - berita_utama',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'berita_utama',
              },
            },
            link: `${BASE_URL}/berita/utama/`,
          },
          {
            menu: 'Terbaru',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - berita_terbaru',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'berita_terbaru',
              },
            },
            link: `${BASE_URL}/berita/terbaru/`,
          },
          {
            menu: 'Kolom',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - berita_kolom',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'berita_kolom',
              },
            },
            link: `${BASE_URL}/berita/kolom/`,
          },
          {
            menu: 'Foto',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - berita_foto',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'berita_foto',
              },
            },
            link: `${BASE_URL}/berita/foto/`,
          },
          {
            menu: 'Jeda',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - berita_jeda',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'berita_jeda',
              },
            },
            link: `${BASE_URL}/berita/jeda/`,
          },
          {
            menu: 'Tajuk',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - berita_tajuk',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'berita_tajuk',
              },
            },
            link: `${BASE_URL}/berita/tajuk/`,
          },
          {
            menu: 'Profil',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - berita_profil',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'berita_profil',
              },
            },
            link: `${BASE_URL}/berita/profil/`,
          },
          {
            menu: 'Isu Hangat',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - berita_isu_hangat',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'berita_isu_hangat',
              },
            },
            link: `${BASE_URL}/berita/isu-hangat/`,
          },
          {
            menu: 'ATP Legal Center',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - berita_pojok_1',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'berita_pojok_1',
              },
            },
            link: `${BASE_URL}/berita/atp-legal-center/`,
          },
          {
            menu: 'Pojok INI',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - berita_pojok_2',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'berita_pojok_2',
              },
            },
            link: `${BASE_URL}/berita/ini/`,
          },
          {
            menu: 'Pojok AKPI',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - berita_pojok_3',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'berita_pojok_3',
              },
            },
            link: `${BASE_URL}/berita/akpi/`,
          },
          // {
          //   menu: 'Pojok KUHP',
          //   tracker: {
          //     snowPlow: {
          //       event: 'navbar',
          //       action: 'click url - berita_pojok_3',
          //       component: 'beranda',
          //     },
          //     ga4: {
          //       event: 'Navbar',
          //       action: 'click url',
          //       component: 'berita_pojok_3',
          //     },
          //   },
          //   link: `${BASE_URL}/berita/pojok-kuhp/`,
          // },
          {
            menu: 'Kabar Kampus',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - berita_kabar_kampus',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'berita_kabar_kampus',
              },
            },
            link: `${BASE_URL}/berita/kabar-kampus/`,
          },
          {
            menu: 'Surat Pembaca',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - berita_surat_pembaca',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'berita_surat_pembaca',
              },
            },
            link: `${BASE_URL}/berita/surat-pembaca/`,
          },
        ],
      },
      {
        menu: 'Hukumonline Stream',
        tracker: {
          snowPlow: {
            event: 'Navbar',
            action: 'click url - hukumonline_stream',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'hukumonline_stream',
          },
        },
        link: `${BASE_URL}/stream/`,
      },
      {
        menu: 'Data Pribadi',
        tracker: {
          snowPlow: {
            event: 'Navbar',
            action: 'click url - datapribadi',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'datapribadi',
          },
        },
        link: 'https://www.datapribadi.id/',
      },
      {
        menu: 'Jurnal',
        tracker: {
          snowPlow: {
            event: 'Navbar',
            action: 'click url - jurnal_home',
            component: 'berita',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'jurnal_home',
          },
        },
        link: JURNAL_URL,
        subMenu: [
          {
            menu: 'Home',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - jurnal_home',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'jurnal_home',
              },
            },
            link: `${JURNAL_URL}/`,
          },
          {
            menu: 'Jurnal',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - jurnal_jurnal',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'jurnal_jurnal',
              },
            },
            link: `${JURNAL_URL}/journal/`,
          },
          {
            menu: 'Artikel',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - jurnal_artikel',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'jurnal_artikel',
              },
            },
            link: `${JURNAL_URL}/article/`,
          },
        ],
      },
    ],
  },
  event: {
    mainTitle: 'Events & Awards',
    link: `${BASE_URL}/events/`,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#ffffff"
        viewBox="0 0 256 256"
        className="mr-1"
      >
        <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.34-85.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L116,164.69l42.34-42.35A8,8,0,0,1,169.66,122.34Z" />
      </svg>
    ),
    iconDark: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="currentColor"
        viewBox="0 0 256 256"
        className="mr-1"
      >
        <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.34-85.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L116,164.69l42.34-42.35A8,8,0,0,1,169.66,122.34Z" />
      </svg>
    ),
    tracker: {
      snowPlow: {
        event: 'navbar',
        action: 'click url - m_hol_event_awards',
        action_v: 'click url - m_hamburger_event_awards',
        component: 'beranda',
      },
      ga4: {
        event: 'navbar',
        action: 'click url',
        component: 'm_hol_event_awards',
        component_v: 'm_hamburger_event_awards',
      },
    },
    subMenu: [
      {
        menu: 'Event',
        tracker: {
          snowPlow: {
            event: 'Navbar',
            action: 'click url - event_home',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'event_home',
          },
        },
        link: `${BASE_URL}/events/`,
        subMenu: [
          {
            menu: 'Home',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - home',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'home',
              },
            },
            link: `${BASE_URL}/events/`,
          },
          {
            menu: 'Kegiatan Terkini',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - event_kegiatan_terkini',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'event_kegiatan_terkini',
              },
            },
            link: `${BASE_URL}/events/kegiatanterkini/`,
          },
          {
            menu: 'Event Organizer',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - event_event_organizer',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'event_event_organizer',
              },
            },
            link: `${BASE_URL}/events/eo/`,
          },
          {
            menu: 'In-House Training',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - event_iht',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'event_iht',
              },
            },
            link: `${BASE_URL}/events/iht/`,
          },
          {
            menu: 'Arsip Kegiatan',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - event_arsip_kegiatan',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'event_arsip_kegiatan',
              },
            },
            link: `${BASE_URL}/events/arsipkegiatan/`,
          },

          {
            menu: 'Narasumber',
            tracker: {
              snowPlow: {
                event: 'navbar',
                action: 'click url - event_narasumber',
                component: 'beranda',
              },
              ga4: {
                event: 'Navbar',
                action: 'click url',
                component: 'event_narasumber',
              },
            },
            link: `${BASE_URL}/events/narasumber/`,
          },
        ],
      },

      {
        menu: 'Awards',
        tracker: {
          snowPlow: {
            event: 'Navbar',
            action: 'click url - ranking_home',
            component: 'ranking',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'ranking_home',
          },
        },
        link:
          'https://awards.hukumonline.com/?utm_source=website&utm_medium=header',
      },

      {
        menu: 'Practice Leaders',
        tracker: {
          snowPlow: {
            event: 'Navbar',
            action: 'click url - practice_leaders_home',
            component: 'publikasi online',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'practice_leaders_home',
          },
        },
        link: `${BASE_URL}/practice-leaders`,
      },

      {
        menu: 'Publikasi Online',
        tracker: {
          snowPlow: {
            event: 'Navbar',
            action: 'click url - online_publication_home',
            component: 'publikasi online',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'online_publication_home',
          },
        },
        link: `${BASE_URL}/online-publication/`,
      },

      {
        menu: 'Online Course',
        tracker: {
          snowPlow: {
            event: 'Navbar',
            action: 'click url - online_course_home',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'online_course_home',
          },
        },
        link: OC_URL,
      },
      {
        menu: 'PKPA',
        tracker: {
          snowPlow: {
            event: 'Navbar',
            action: 'click url - pkpa-home',
            component: 'beranda',
          },
          ga4: {
            event: 'Navbar',
            action: 'click url',
            component: 'pkpa-home',
          },
        },
        link:
          'https://pkpa.hukumonline.com/?utm_source=website&utm_medium=header',
      },
    ],
  },
};
export default menuList;
