import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import { structuredEvent, pushGa4Generic } from '../../libraries/tracker';

import { getCookie, setCookieWithExpire } from '../../libraries/session';

import stylesheet from './buttonWorkspace.scss';
import { useCoachmarkV2Context } from '../coachMark/v2/CoachmarkV2Provider';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL } = publicRuntimeConfig;

export default function ButtonWorkspace() {
  const { setDashboardProCoachmarkTriggered } = useCoachmarkV2Context();
  // it will only appear once if user never click the button
  const [isDashboardProCoachmark, setIsDashboardProCoachmark] = useState(() => {
    const cookie = getCookie('dashboardProCoachmark');
    return cookie === undefined;
  });
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  const handleSetCookieDashboardCoachmark = () => {
    setCookieWithExpire('dashboardProCoachmark', true, 300);
    setIsDashboardProCoachmark(false);
    setDashboardProCoachmarkTriggered(true);
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  });

  if (!isClient) return <div />;
  if (!isDesktopOrLaptop) return <div />;

  return (
    <>
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: stylesheet }}
      />
      <div
        style={{
          position: 'relative',
          zIndex: isDashboardProCoachmark ? 101 : 0,
        }}
      >
        <a
          href="https://pro.hukumonline.com/workspace/"
          className="button-workspace"
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - workspace',
              'workspace',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'click url',
              component: 'workspace',
            });
            handleSetCookieDashboardCoachmark();
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.9 4C3.29837 4 2 5.29837 2 6.9V15.5268C2 17.1285 3.29837 18.4268 4.9 18.4268H7.7666V16.6268H4.9C4.29249 16.6268 3.8 16.1343 3.8 15.5268V6.9C3.8 6.29249 4.29249 5.8 4.9 5.8H18.533C19.1405 5.8 19.633 6.29249 19.633 6.9V13.7637H21.433V6.9C21.433 5.29838 20.1346 4 18.533 4H4.9Z"
              fill="white"
            />
            <path
              d="M16 13C16.5528 14.9348 18.0652 16.4472 20 17C18.0652 17.5528 16.5528 19.0652 16 21C15.4472 19.0652 13.9348 17.5528 12 17C13.9348 16.4472 15.4472 14.9348 16 13Z"
              fill="white"
            />
            <path
              d="M12 8C12.4146 9.45111 13.5489 10.5854 15 11C13.5489 11.4146 12.4146 12.5489 12 14C11.5854 12.5489 10.4511 11.4146 9 11C10.4511 10.5854 11.5854 9.45111 12 8Z"
              fill="white"
            />
          </svg>
          <span className="button-workspace__text">My Workspace</span>
        </a>

        {/* POPUP TOOLTIP WHEN USER NOT CLICK YET */}
        {isDashboardProCoachmark && (
          <div className="coachmark-workspace">
            <div className="position-relative">
              <div className="coachmark-workspace__title">
                Jelajahi My Workspace
              </div>
              <div className="coachmark-workspace__description">
                Jelajahi workspace dan personalisasi tampilan halaman Anda
              </div>
              <div className="coachmark-workspace__cta">
                <a
                  href="https://pro.hukumonline.com/workspace/"
                  target="_blank"
                  rel="noreferrer"
                  title="https://pro.hukumonline.com/workspace/"
                  onClick={() => handleSetCookieDashboardCoachmark()}
                  className="w-100"
                >
                  <Button
                    color="secondary"
                    size="sm"
                    block
                    style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
                  >
                    Jelajahi Sekarang
                  </Button>
                </a>
                <Button
                  size="sm"
                  color="primary"
                  style={{
                    color: '#F5F7F9',
                    padding: '0.5rem 1rem',
                  }}
                  onClick={() => handleSetCookieDashboardCoachmark()}
                >
                  Tutup
                </Button>
              </div>

              <img
                src={`${BASE_URL}/static/images/tooltip-tail.svg`}
                alt="tooltip-tail"
                style={{
                  position: 'absolute',
                  right: '-0.125rem',
                  top: '-1.3125rem',
                }}
              />
            </div>
          </div>
        )}
        {/* POPUP TOOLTIP WHEN USER NOT CLICK YET */}
      </div>
      {/* BACKGROUND WHEN POPUP TOOLTIP APPEAR */}
      {isDashboardProCoachmark && (
        <div className="coachmark-workspace__background" />
      )}
      {/* BACKGROUND WHEN POPUP TOOLTIP APPEAR */}
    </>
  );
}
