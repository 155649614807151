import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';
import { Bling as GPT } from 'react-gpt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import slick from 'slick-carousel/slick/slick.css';
import slickTheme from 'slick-carousel/slick/slick-theme.css';
import BackToTop from 'react-back-to-top-button';
import axios from 'axios';
import ErrorPage from '../_error';
import { Landing } from '../../services/landing';
import Layout from '../../layouts/landingLayout';
import {
  SliderMain,
  SliderMedia,
  EventsListSlider,
  EventListMobile,
  InfoGrafisSlider,
  SliderProduct,
} from '../../components/landing/slider';
import {
  LabelStatus,
  Headline,
  LabelPro,
  IsPaid,
} from '../../components/common/labelStatus';
import {
  PusatData,
  MediaHighlight,
  MediaList,
  PremiumNewsList,
  SectionTerbaru,
  PremiumStories,
  KlinikHighlight,
  KlinikList,
  PojokHighlight,
  PojokList,
  HolProduct,
  KonsolidasiInfo,
  Pojok,
  OnlineCourses,
  LegalIntelligence,
} from '../../components/landing/list';
import styleSheet from './index.scss';
import styleSlider from '../../components/landing/slider.scss';
import { withCommons } from '../../libraries/commons';
import { trackerMain } from '../../libraries/tracker';
import 'react-lazy-load-image-component/src/effects/blur.css';
// import ModalPushNotification from '../../components/common/PushNotificationModalValidator';
import { LazyImageWithFallback } from '../../components/common/ImageWithFallback';
import HolproSection from '../../components/landing/holproSection';
import FloatingBox from '../../components/floatingBox';

GPT.enableSingleRequest();
class StaticPage extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      eventCategoriesActive: 'lt4a0a9c81379dfa',
    };
    this.downloadSample = this.downloadSample.bind(this);
  }

  static async getInitialProps(ctx) {
    // const home = require('./home.json');
    const home = await Landing.main();
    const courses = []; // await Landing.courses();

    if (home && home.status) {
      ctx.res.status(505);
    }

    // home.data.pojok = staticData.data.pojok;
    return { home, courses };
  }

  // eslint-disable-next-line class-methods-use-this
  downloadSample() {
    const sampleProduct = {
      sample:
        '/static/documents/samples/konsolidasi-undang-undang-No-13-tahun-2003-sample.pdf',
      slug: 'Konsolidasi Undang-Undang No.13 Tahun 2003',
    };

    axios({
      url: sampleProduct.sample,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${sampleProduct.slug}.pdf`);
      document.body.appendChild(link);
      link.click();
      trackerMain({
        snowplow: [],
        ga4: [
          'eventCoreV1BasicData',
          '',
          'section_pusat_data',
          'file_download',
          `button contoh peraturan konsolidasi`,
          '',
          '',
          '',
        ],
      });
    });
  }

  render() {
    const { home = {}, courses = {}, ...rest } = this.props;
    if (home && home.status) {
      return <ErrorPage statusCode={505} {...rest} />;
    }
    const sliderData = home.data.slider.map((v) => ({ ...v, key: v.guid }));
    const fotoVideoMobile = home?.data?.foto_dan_youtube?.slice(0, 2);

    const ORG_SCHEMA = JSON.stringify({
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'WebSite',
          '@id': 'https://www.hukumonline.com',
          url: 'https://www.hukumonline.com',
          name: 'Hukumonline',
          description: 'Hukumonline',
          potentialAction: [
            {
              '@type': 'SearchAction',
              target:
                'https://search.hukumonline.com/search/all/?q={search_term_string}',
              'query-input': 'required name=search_term_string',
            },
          ],
          inLanguage: 'id-ID',
        },
      ],
    });
    return (
      <>
        {/* <ModalPushNotification /> */}
        <Layout
          {...this.props}
          metaKey="homepageMain"
          metaType="static"
          searchLocationTracker="new homepage - header"
          searchActionTracker="input text and search/enter - search bar"
        >
          <FloatingBox />
          <style dangerouslySetInnerHTML={{ __html: styleSheet }} />
          <style dangerouslySetInnerHTML={{ __html: styleSlider }} />
          <style dangerouslySetInnerHTML={{ __html: slick }} />
          <style dangerouslySetInnerHTML={{ __html: slickTheme }} />
          <Container className="py-md-5">
            <div className="headline-section mb-md-5">
              <Row className="pb-md-3">
                <Col md={8} className="px-0 px-md-3">
                  <SliderMain
                    data={sliderData.slice(0, 4)}
                    location="HOL - New Homepage"
                  />
                  <div className="mt-3 d-md-flex justify-content-center d-none">
                    <GPT
                      adUnitPath="60173473/homepage/leaderboard"
                      sizeMapping={[{ viewport: [750, 0], slot: [728, 90] }]}
                    />
                  </div>
                  <div className="py-md-2 my-4 d-flex justify-content-center  d-md-none ">
                    <GPT
                      adUnitPath="60173473/homepage/leaderboard"
                      sizeMapping={[{ viewport: [320, 0], slot: [320, 50] }]}
                    />
                  </div>
                </Col>

                <Col md={4} className="">
                  <Row>
                    <Col md={1}>
                      <hr width="1" size="500" className="vertical m-0" />
                    </Col>
                    <Col md={11}>
                      <div className="d-md-block d-none">
                        <GPT
                          adUnitPath="60173473/homepage/medium-rectangle-a"
                          sizeMapping={[{ slot: [300, 250] }]}
                        />
                      </div>
                      <LegalIntelligence data={home.data.legalintelligence} />
                      <SectionTerbaru
                        data={home.data.terbaru}
                        location="HOL - New Homepage"
                      />
                      <div className="d-md-none p-0 row d-flex justify-content-center mt-md-3 mb-4 ">
                        <GPT
                          adUnitPath="60173473/homepage/medium-rectangle-a"
                          sizeMapping={[{ slot: [300, 250] }]}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="second-section mb-md-5">
              <Row className="pb-md-3 mb-4">
                <Col md={4}>
                  <PusatData
                    data={home.data.pusat_data}
                    location="HOL - New Homepage"
                  />
                  <KonsolidasiInfo
                    className="d-md-flex d-none"
                    downloadSample={this.downloadSample}
                    location="HOL - New Homepage"
                  />
                </Col>
                <Col md={8} className="overflow-hidden">
                  <div id="section-legal-analysis" className="section">
                    <div className="section-head d-flex justify-content-between align-items-center border-bottom">
                      <div className="d-inline-flex flex-row flex-inline align-items-center ">
                        <h3>Legal Analysis</h3>
                        <div className="ml-2">
                          <IsPaid />
                        </div>
                      </div>
                      <div className="read-more">
                        <a
                          href="https://pro.hukumonline.com/home?utm_source=website&utm_medium=home&utm_campaign=home_legal_analysis"
                          className="d-md-block d-none text-uppercase"
                          onClick={() => {
                            trackerMain({
                              snowplow: [],
                              ga4: [
                                'eventCoreV1BasicData',
                                '',
                                'section_legal_analysis',
                                'link_click',
                                `button lihat selengkapnya legal analysis`,
                                '',
                                '',
                                '',
                              ],
                            });
                          }}
                        >
                          <span>
                            Selengkapnya{' '}
                            <i className="fa fa-arrow-right ml-2" />
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="section-main-legal-analysis">
                      <div className="legal-analysis-highligh my-2">
                        <MediaHighlight
                          data={home.data.legal_analysis.highlight}
                          location="HOL - New Homepage"
                        />
                      </div>
                      <Row className="d-md-flex d-none">
                        <MediaList
                          data={home.data.legal_analysis.data}
                          location="HOL - New Homepage"
                        />
                      </Row>
                    </div>
                  </div>
                  <KonsolidasiInfo
                    className="d-flex d-md-none"
                    downloadSample={this.downloadSample}
                    location="HOL - New Homepage"
                  />
                </Col>
              </Row>
            </div>
            <HolproSection order={1} />
          </Container>

          <div className="bg-grey  py-md-5">
            <Container>
              <div className="premium-news-section py-md-2 mb-md-5 mb-4">
                <div className="section-head d-md-flex d-none flex-column justify-content-center align-items-center">
                  <h3>Premium Stories</h3>
                  <span>
                    Temukan artikel hukum komprehensif dari Hukumonline!
                  </span>
                </div>
                <div className="section-head d-flex d-md-none justify-content-between align-items-center border-bottom">
                  <h3>Premium Stories</h3>
                </div>
                <div className="section-main-premium-news my-md-4 my-2">
                  <Row>
                    <Col md={6}>
                      <PremiumNewsList
                        data={home.data.premium_news.highlight}
                        location="HOL - New Homepage"
                      />
                      <hr className="d-md-none" />
                    </Col>
                    <Col
                      md={6}
                      className="d-flex flex-column justify-content-between"
                    >
                      <PremiumStories
                        data={home.data.premium_news.data}
                        location="HOL - New Homepage"
                      />
                    </Col>
                  </Row>
                  <div className="mt-3 d-flex justify-content-center" />
                  <div className="read-more d-flex justify-content-center">
                    <Button
                      color="primary"
                      href="/stories/"
                      className="btn btn-primary text-capitalize d-md-block d-none mt-3"
                      onClick={() =>
                        trackerMain({
                          snowplow: [
                            'HOL - New Homepage',
                            'click button - blok premium stories - lihat semua',
                            '',
                            '',
                            '',
                          ],
                          ga4: [
                            'eventCoreV1BasicData',
                            'New - New Homepage',
                            'section_premium_stories',
                            'button_click',
                            'button lihat selengkapnya premium stories',
                            '',
                            '',
                            '',
                          ],
                        })
                      }
                    >
                      Lihat Semua
                    </Button>
                    <a
                      href="/stories/"
                      className="btn btn-outline-primary btn-block btn-more d-md-none py-2 my-md-2 my-1"
                      onClick={() =>
                        trackerMain({
                          snowplow: [
                            'HOL - New Homepage',
                            'click button - blok premium stories - lihat semua',
                            '',
                            '',
                            '',
                          ],
                          ga4: [
                            'eventCoreV1BasicData',
                            'New - New Homepage',
                            'section_premium_stories',
                            'button_click',
                            'button lihat selengkapnya premium stories',
                            '',
                            '',
                            '',
                          ],
                        })
                      }
                    >
                      <span>Lihat Semua Premium Stories </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="klinik-section pb-md-4 mb-md-5 mb-4">
                <div className="section-head d-flex justify-content-between align-items-center border-bottom">
                  <h3>Klinik Hukum</h3>
                  <div className="read-more">
                    <a
                      href="/klinik"
                      className="d-md-block d-none text-uppercase"
                      onClick={() =>
                        trackerMain({
                          snowplow: [
                            'HOL - New Homepage',
                            'click link - blok klinik hukum - lihat semua',
                            '',
                            '',
                            '',
                          ],
                          ga4: [
                            'eventCoreV1BasicData',
                            'New - New Homepage',
                            'section_klinik_hukum',
                            'button_click',
                            'button lihat semua klinik hukum',
                            '',
                            '',
                            '',
                          ],
                        })
                      }
                    >
                      <span>
                        Lihat Semua <i className="fa fa-arrow-right ml-2" />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="section-main-klinik my-md-2 my-3">
                  {/* <div className="row flex-row flex-nowrap overflow-auto horizontal-scroll mb-2 d-md-none">
                    <div className="col-12">
                      {home.data.event_categories.map(item => (
                        <LabelPro
                          data={item.title}
                          active={this.state.eventCategoriesActive == item.guid}
                          multiple
                        />
                      ))}
                    </div>
                  </div> */}
                  <Row>
                    <Col md={6}>
                      <KlinikHighlight data={home.data.klinik_hukum} />
                    </Col>
                    <Col md={6}>
                      <Row>
                        <KlinikList
                          data={home.data.klinik_hukum.data}
                          location="HOL - New Homepage"
                        />
                      </Row>
                    </Col>
                  </Row>
                  <div className="read-more my-3">
                    <a
                      href="/klinik"
                      className="btn btn-outline-primary btn-block btn-more d-md-none "
                      onClick={() =>
                        trackerMain({
                          snowplow: [
                            'HOL - New Homepage',
                            'click link - blok klinik hukum - lihat semua',
                            '',
                            '',
                            '',
                          ],
                          ga4: [
                            'eventCoreV1BasicData',
                            'New - New Homepage',
                            'section_klinik_hukum',
                            'button_click',
                            'button lihat semua klinik hukum',
                            '',
                            '',
                            '',
                          ],
                        })
                      }
                    >
                      <span>Lihat Semua Klinik </span>
                    </a>
                  </div>
                  <div className="info-section my-4">
                    <Row>
                      <Col md={6} className="pro-info">
                        <div className="d-flex flex-column  py-md-3 px-md-5 p-3 info-product my-2">
                          <span className="info py-1">Ada masalah hukum?</span>
                          <span className="content">
                            Dapatkan saran dari konsultan hukum berpengalaman,
                            mulai dari Rp 30.000,
                          </span>
                          <div className="mt-3">
                            <Button
                              href="https://www.justika.com/?utm_source=hukumonline&utm_medium=klinik&utm_campaign=homepage&utm_content=chat"
                              target="_blank"
                              rel="noreferrer"
                              className="btn text-capitalize d-inline-flex align-items-center"
                              color="primary"
                              onClick={() =>
                                trackerMain({
                                  snowplow: [
                                    'HOL - New Homepage',
                                    'click button - justika cta',
                                    '',
                                    '',
                                    '',
                                  ],
                                  ga4: [
                                    'eventCoreV1BasicData',
                                    'New - New Homepage',
                                    'section_klinik_hukum',
                                    'button_click',
                                    'button konsultasi sekarang klinik hukum',
                                    '',
                                    '',
                                    '',
                                  ],
                                })
                              }
                            >
                              <span className="icon-user-tie" />
                              Konsultasi Sekarang
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col md={6} className=" pro-info">
                        <div className="d-flex flex-column  py-md-3 px-md-5 p-3 info-product my-2">
                          <span className="info py-1">
                            Ingin Legalitas Bisnis Anda Terjamin?
                          </span>
                          <span className="content">
                            Akurat, praktis, terjangkau. Sesuaikan kebutuhan
                            pendirian dan izin usahamu dengan aturan terbaru.
                          </span>
                          <div className="mt-3">
                            <Button
                              href="https://www.easybiz.id/"
                              target="_blank"
                              rel="noreferrer"
                              className="btn  text-capitalize d-inline-flex align-items-center"
                              color="primary"
                              onClick={() =>
                                trackerMain({
                                  snowplow: [
                                    'HOL - New Homepage',
                                    'click button - easybiz cta',
                                    '',
                                    '',
                                    '',
                                  ],
                                  ga4: [
                                    'eventCoreV1BasicData',
                                    'New - New Homepage',
                                    'section_klinik_hukum',
                                    'button_click',
                                    'button cari tahu sekarang klinik hukum',
                                    '',
                                    '',
                                    '',
                                  ],
                                })
                              }
                            >
                              <span className="icon-signature" /> Cari Tahu
                              Sekarang
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              {/* <OnlineCourses data={courses} /> */}

              <div className="events-section pb-md-4 mb-md-5 mb-md-4">
                <div className="section-head d-flex justify-content-between align-items-center border-bottom mb-3">
                  <div className="d-flex flex-row align-items-center text-main">
                    <h3>Events</h3>
                    {/* <div className="ml-2 d-md-block d-none">
                      <span className="mx-2">Semua</span>
                      <span className="mx-2">Seminar</span>
                      <span className="mx-2">Pelatihan</span>
                      <span className="mx-2">Diskusi</span>
                      <span className="mx-2">Lain Lain</span>
                    </div> */}
                  </div>
                  <div className="read-more ">
                    <a
                      href="/talks"
                      className="d-md-block d-none text-uppercase"
                      onClick={() =>
                        trackerMain({
                          snowplow: [
                            'HOL - New Homepage',
                            'click link - blok events - lihat semua',
                            '',
                            '',
                            '',
                          ],
                          ga4: [
                            'eventCoreV1BasicData',
                            'New - New Homepage',
                            'section_event',
                            'button_click',
                            'button lihat semua event',
                            '',
                            '',
                            '',
                          ],
                        })
                      }
                    >
                      <span>
                        Lihat Semua <i className="fa fa-arrow-right ml-2" />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="section-main-events">
                  {/* <div className="row flex-row flex-nowrap overflow-auto horizontal-scroll mb-3 d-md-none">
                    <div className="col-12">
                      {home.data.event_categories.map(item => (
                        <LabelPro
                          data={item.title}
                          active={this.state.eventCategoriesActive == item.guid}
                          multiple
                        />
                      ))}
                    </div>
                  </div> */}
                  <EventsListSlider
                    data={home.data.events[0]}
                    location="HOL - New Homepage"
                  />
                  <EventListMobile
                    border
                    data={home.data.events[0]}
                    location="HOL - New Homepage"
                  />
                </div>
              </div>
              <div className="section-es d-md-none d-block py-3">
                <div className="section-head d-flex justify-content-between align-items-center border-bottom">
                  <div className="d-flex flex-row align-items-center text-main">
                    <h3>Enterprise Solution</h3>
                  </div>
                </div>
                <div className="section-content">
                  {home.data.produk_lainnya.map((item, index) => (
                    <div className="content border-bottom py-3" key={index}>
                      <a
                        className="small font-weight-bold"
                        href={item?.link}
                        onClick={() =>
                          trackerMain({
                            snowplow: [
                              'HOL - New Homepage',
                              `click title - blok enterprise solution - ${item.title}`,
                              '',
                              '',
                              '',
                            ],
                            ga4: [
                              'eventCoreV1BasicData',
                              'New - New Homepage',
                              'section_enterprise_solution',
                              'link_click',
                              `title ${item.id == 1 ? 'rcs' : 'exdoma'}`,
                              '',
                              '',
                              '',
                            ],
                          })
                        }
                      >
                        <h4 className="header-6 font-weight-bold">
                          {item?.title}
                        </h4>

                        <LazyImageWithFallback
                          fallback="https://static.hukumonline.com/frontend/default/images/kaze/default.jpg"
                          alt={item?.title}
                          src={item?.image}
                          className="img-fluid featured-pojok-background __image"
                        />
                      </a>
                      <p className="small text-washed mt-2 mb-0">
                        {item?.description}{' '}
                        <a
                          className="extra-small font-weight-semibold text-hol-blueActive"
                          href={item?.link}
                          onClick={() => {
                            trackerMain({
                              snowplow: [
                                'HOL - New Homepage',
                                `click title - blok enterprise solution - ${item.title}`,
                                '',
                                '',
                                '',
                              ],
                              ga4: [
                                'eventCoreV1BasicData',
                                'New - New Homepage',
                                'section_enterprise_solution',
                                'button_click',
                                `button lihat selengkapnya ${
                                  item.id == 1 ? 'rcs' : 'exdoma'
                                }`,
                                '',
                                '',
                                '',
                              ],
                            });
                          }}
                        >
                          Selengkapnya <i className="fa fa-arrow-right ml-2" />
                        </a>{' '}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="other-product-section  pb-md-2 d-md-block d-none">
                <div className="section-head d-flex flex-column justify-content-center align-items-center pb-4">
                  <h3>Enterprise Solution</h3>
                </div>
                <SliderProduct
                  data={home.data.produk_lainnya}
                  location="HOL - New Homepage"
                />
              </div>
              <div className="section-es d-md-none d-block py-3">
                <div className="section-head d-flex justify-content-between align-items-center border-bottom">
                  <div className="d-flex flex-row align-items-center text-main">
                    <h3>Foto & Video</h3>
                  </div>
                </div>
                <div className="section-content">
                  {fotoVideoMobile?.map((item, index) => (
                    <div className="content border-bottom py-3" key={index}>
                      <a
                        href={item?.link}
                        onClick={() => {
                          trackerMain({
                            snowplow: [
                              'HOL - New Homepage',
                              'click link artikel - blok foto and video',
                              `${item.guid}`,
                              '',
                              `${index}`,
                            ],
                            ga4: [
                              'eventCoreV1ArticleFotoVideo',
                              'HOL - New Homepage',
                              'section_foto_dan_video',
                              'image_click',
                              `image foto dan video`,
                              index,
                              `${item.title}`,
                              `${item.guid}`,
                            ],
                          });
                        }}
                      >
                        <LazyImageWithFallback
                          fallback="https://static.hukumonline.com/frontend/default/images/kaze/default.jpg"
                          alt={item?.title}
                          src={item?.image}
                          className="img-fluid featured-pojok-background __image"
                        />
                      </a>
                      <a
                        href={item?.link}
                        onClick={() => {
                          trackerMain({
                            snowplow: [
                              'HOL - New Homepage',
                              'click link artikel - blok foto and video',
                              `${item.guid}`,
                              '',
                              `${index}`,
                            ],
                            ga4: [
                              'eventCoreV1ArticleFotoVideo',
                              'HOL - New Homepage',
                              'section_foto_dan_video',
                              'click',
                              `title highlighted article `,
                              index,
                              `${item.title}`,
                              `${item.guid}`,
                            ],
                          });
                        }}
                      >
                        <h4 className="header-6 font-weight-bold mt-3 mb-0">
                          {item?.title}
                        </h4>
                      </a>
                    </div>
                  ))}
                </div>
                <div className="read-more my-3  d-md-none">
                  <a
                    href="/fotovideo"
                    className="btn btn-more btn-outline-primary btn-block"
                    onClick={() =>
                      trackerMain({
                        snowplow: [
                          'HOL - New Homepage',
                          'click link - blok foto and video - lihat semua',
                          '',
                          '',
                          '',
                        ],
                        ga4: [
                          'eventCoreV1BasicData',
                          'HOL - New Homepage',
                          'section_foto_dan_video',
                          'button_click',
                          `button lihat semua foto dan video`,
                          '',
                          '',
                          '',
                        ],
                      })
                    }
                  >
                    <span>Lihat Semua Foto & Video </span>
                  </a>
                </div>
              </div>
            </Container>
          </div>
          <Container className="my-md-5">
            <div className="foto-section d-md-block d-none py-md-2">
              <SliderMedia
                data={home.data.foto_dan_youtube}
                location="HOL - New Homepage"
              />
            </div>
            <div className="advertorial-section py-4">
              <Row>
                <Col md={8} className="order-md-1 order-2">
                  {Object.keys(home.data.pojok).map((item, index) => (
                    <Pojok
                      {...home.data.pojok[item]}
                      title={home.data.pojok[item].title}
                      slug={home.data.pojok[item].slug}
                      location="HOL - New Homepage"
                      key={index}
                    />
                  ))}
                </Col>
                <Col md={4} className="order-md-2 order-1">
                  <div
                    id="section-infografis"
                    className="section sticky-top-infografis"
                  >
                    <div className="section-head d-flex justify-content-between align-items-center border-bottom">
                      <h3>Infografik</h3>
                      <div className="read-more">
                        <a
                          href="/klinik/infografik"
                          className="d-md-block d-none text-uppercase"
                          onClick={() =>
                            trackerMain({
                              snowplow: [
                                'HOL - New Homepage',
                                'click link - blok infografik - lihat semua',
                                '',
                                '',
                                '',
                              ],
                              ga4: [
                                'eventCoreV1BasicData',
                                'New - New Homepage',
                                'section_infografik',
                                'button_click',
                                'button selengkapnya',
                                '',
                                '',
                                '',
                              ],
                            })
                          }
                        >
                          <span>
                            Lihat Semua <i className="fa fa-arrow-right ml-2" />
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="section-content my-2 d-flex flex-row">
                      <InfoGrafisSlider
                        data={home.data.infografis}
                        location="HOL - New Homepage"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <BackToTop
              showAt={150}
              speed={1500}
              easing="easeInOutQuint"
              className={`${!this.state.filterShow ? 'd-none' : ''}`}
            >
              <div>
                <img
                  src="/static/images/ic_nav.png"
                  className="img-fluid service-title m-0"
                  alt="circle with chevron up"
                  title="back to top"
                  onClick={() => {
                    trackerMain({
                      snowplow: [],
                      ga4: [
                        'eventCoreV1BasicData',
                        'New - New Homepage',
                        'arrow_back_to_top',
                        'button_click',
                        'button arrow back to top',
                        '',
                        '',
                        '',
                      ],
                    });
                  }}
                />
              </div>
            </BackToTop>
            {/* <div className="info-section px-md-5 py-md-4 p-4 my-4 pro-info">
              <Row>
                <Col
                  md={6}
                  className="d-flex flex-column pro-info justify-content-center"
                >
                  <div>
                    <span className="title py-1 d-flex align-items-center">
                      Hukumonline Pro{' '}
                      <IsPaid className="d-md-none d-block ml-2" />{' '}
                      <FontAwesomeIcon
                        width="16"
                        className="ml-2 text-secondary d-md-block d-none"
                        icon={faStar}
                      />
                    </span>
                  </div>

                  <span className="info py-1">
                    Referensi Tepercaya Anda dalam Riset Hukum
                  </span>
                  <span className="subscribe-info">
                    Berlangganan Hukumonline Pro sekarang dan dapatkan:
                  </span>
                  <div className="mt-3 d-md-block d-none">
                    <Button
                      href="https://pro.hukumonline.com/berlangganan-form?paket=proplus&utm_source=website&utm_medium=home&utm_campaign=home_hardcode_holpro"
                      className="btn text-primary text-capitalize"
                      onClick={() =>
                        trackerMain({
                          snowplow: [
                            'HOL - New Homepage',
                            'click button - hol pro cta bottom',
                            '',
                            '',
                            '',
                          ],
                          ga4: [
                            'eventCoreV1BasicData',
                            'New - New Homepage',
                            'section_subscribe_hol_pro_2',
                            'button_click',
                            'button berlangganan sekarang hol pro 2',
                            '',
                            '',
                            '',
                          ],
                        })
                      }
                    >
                      Berlangganan Sekarang{' '}
                      <i className="fa fa-arrow-right ml-2" />
                    </Button>
                  </div>
                </Col>
                <Col md={6} className="pro-feature-info my-2 my-md-0">
                  <ul className="p-0">
                    <li>
                      <i className="fa fa-check mr-2 text-primary" />
                      <p className="m-0">
                        Analisis hukum yang{' '}
                        <strong>tersaji dalam dwi bahasa</strong>
                      </p>
                    </li>
                    <li>
                      <i className="fa fa-check mr-2 text-primary" />
                      <p className="m-0">
                        Koleksi{' '}
                        <strong>
                          peraturan perundang-undangan terbaru serta putusan
                          pengadilan
                        </strong>
                      </p>
                    </li>
                    <li>
                      <i className="fa fa-check mr-2 text-primary" />
                      <p className="m-0">
                        Koleksi <strong>terjemahan peraturan</strong> terkait
                        bisnis
                      </p>
                    </li>
                    <li>
                      <i className="fa fa-check mr-2 text-primary" />
                      <p className="m-0">
                        <strong>Peraturan Konsolidasi </strong>yang merupakan{' '}
                        <strong>
                          naskah penggabungan dari sebuah peraturan dan setiap
                          perubahannya, yang disertai dengan anotasi putusan.
                        </strong>
                      </p>
                    </li>
                  </ul>
                  <div className="mt-3 d-md-none">
                    <Button
                      href="https://pro.hukumonline.com/"
                      className="btn  btn-primary text-primary text-capitalize btn-block"
                      onClick={() =>
                        trackerMain({
                          snowplow: [
                            'HOL - New Homepage',
                            'click button - hol pro cta bottom',
                            '',
                            '',
                            '',
                          ],
                          ga4: [
                            'eventCoreV1BasicData',
                            'New - New Homepage',
                            'section_subscribe_hol_pro_2',
                            'button_click',
                            'button berlangganan sekarang hol pro 2',
                            '',
                            '',
                            '',
                          ],
                        })
                      }
                    >
                      Berlangganan Sekarang{' '}
                      <i className="fa fa-arrow-right ml-2" />
                    </Button>
                  </div>
                </Col>
              </Row>
            </div> */}
            <HolproSection order={2} />
          </Container>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: ORG_SCHEMA }}
          />
        </Layout>
      </>
    );
  }
}

export default withCommons(StaticPage);
