/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import _ from 'lodash';
import {
  Container,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Button,
  Row,
  Media,
  Col,
  Collapse,
} from 'reactstrap';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll';

import slugify from 'slugify';
import FontAwesome from 'react-fontawesome';

import Moment from 'react-moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { structuredEvent, trackerMain } from '../../libraries/tracker';
import { imageSelector } from '../../libraries/utils';
import { LazyImageWithFallback } from '../common/ImageWithFallback';
import TextSanitizer from '../textSanitizer';

const items = [
  {
    src: 'https://picsum.photos/1280/720',
    altText: 'MA Bakal Terbitkan Perma Sidang Pidana Online',
    caption:
      'Diharapkan dalam satu bulan ke depan, draf Perma tentang Administrasi Persidangan Perkara Pidana...',
  },
  {
    src: 'https://picsum.photos/1280/720',
    altText: 'MA Bakal Terbitkan Perma Sidang Pidana Online',
    caption:
      'Diharapkan dalam satu bulan ke depan, draf Perma tentang Administrasi Persidangan Perkara Pidana...',
  },
  {
    src: 'https://picsum.photos/1280/720',
    altText: 'MA Bakal Terbitkan Perma Sidang Pidana Online',
    caption:
      'Diharapkan dalam satu bulan ke depan, draf Perma tentang Administrasi Persidangan Perkara Pidana...',
  },
  {
    src: 'https://placekitten.com/1280/720',
    altText: 'MA Bakal Terbitkan Perma Sidang Pidana Online',
    caption:
      'Diharapkan dalam satu bulan ke depan, draf Perma tentang Administrasi Persidangan Perkara Pidana...',
  },
  {
    src: 'https://picsum.photos/1280/720',
    altText: 'MA Bakal Terbitkan Perma Sidang Pidana Online',
    caption:
      'Diharapkan dalam satu bulan ke depan, draf Perma tentang Administrasi Persidangan Perkara Pidana...',
  },
];

const linkGen = (data, typeList) => {
  let link = '';
  let type = data.type || typeList;

  if (data.question) {
    type = 'klinik';
  }

  switch (type.trim().toLowerCase()) {
    case 'events':
      link = `/talks/baca/${data.guid}/${slugify(
        data.title.toLowerCase(),
        '-',
      )}/`;
      break;
    case 'berita':
    case 'utama':
      link = `/berita/a/${data.slug}-${data.guid}/`;
      break;
    case 'foto':
      link = `/berita/foto/f/${slugify(data.title.toLowerCase(), '-')}-${
        data.guid
      }/`;
      break;
    case 'talks':
      link = `/talks/baca/${data.guid}/${slugify(
        data.title.toLowerCase(),
        '-',
      )}/`;
      break;
    case 'talks event':
      link = `/talks/baca/${data.guid}/${slugify(
        data.title.toLowerCase(),
        '-',
      )}/`;
      break;
    case 'grafis':
      link = `/klinik/infografik/${slugify(data.title.toLowerCase(), '-')}-${
        data.guid
      }/`;
      break;
    case 'klinikgrafis':
      link = `/klinik/infografik/${slugify(data.title.toLowerCase(), '-')}-${
        data.guid
      }/`;
      break;
    case 'klinik':
      link = `/klinik/a/${slugify(data.title.toLowerCase(), '-')}-${
        data.guid
      }/`;
      break;
    case 'stories':
      link = `/stories/article/${data.id}/${data.slug.toLowerCase()}/`;
      break;
    default:
      link = `/berita/a/${data.slug}-${data.guid}/`;
  }

  return link;
};

const newLinkGen = (data, typeList) => {
  let link = '';
  let type = data.type || typeList;

  if (data.question) {
    type = 'klinik';
  }

  switch (type.trim().toLowerCase()) {
    case 'events':
      link = `/talks/baca/${data.guid}/${data.slug}/`;
      break;
    case 'berita':
    case 'utama':
      link = `/berita/a/${data.slug}-${data.guid}/`;
      break;
    case 'foto':
      link = `/berita/foto/f/${data.slug}-${data.guid}/`;
      break;
    case 'talks':
      link = `/talks/baca/${data.guid}/${data.slug}/`;
      break;
    case 'talks event':
      link = `/talks/baca/${data.guid}/${data.slug}/`;
      break;
    case 'grafis':
      link = `/klinik/infografik/${data.slug}-${data.guid}/`;
      break;
    case 'klinikgrafis':
      link = `/klinik/infografik/${data.slug}-${data.guid}/`;
      break;
    case 'klinik':
      link = `/klinik/a/${data.slug}-${data.guid}/`;
      break;
    case 'stories':
      link = `/stories/article/${data.id}/${data.slug.toLowerCase()}/`;
      break;
    default:
      link = `/berita/a/${data.slug}-${data.guid}/`;
  }

  return link;
};

export const SliderMainFullWidthV2 = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === props.data.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? props.data.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => previous(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // location defined for event tracker
  const { location } = props;

  const slides = props.data.map((item, index) => {
    const type = props.type || item.type.toLowerCase();
    const imageFile = `https://images.hukumonline.com/frontend/${item.featured_image_parent_path}/${item.featured_image}`;
    const imageLarge = `https://images.hukumonline.com/frontend/${item.featured_image_parent_path}/large_${item.featured_image}`;
    const imageMedium = `https://images.hukumonline.com/frontend/${item.featured_image_parent_path}/medium_${item.featured_image}`;
    const avatar = `https://images.hukumonline.com/frontend/${item.author_parent_guid}/${item.author_avatar}`;

    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
        interval={5000}
        className={`${
          props.roundedMobile ? 'rounded-carousel-mobile' : ''
        } main`}
      >
        <picture className="featured-banner-background">
          <source media="(max-width: 578px)" srcSet={imageMedium} />
          <source media="(min-width: 800px)" srcSet={imageLarge} />
          <img
            src={imageFile}
            alt={item.altText}
            className="img-fluid featured-banner-background __image"
          />
        </picture>

        <div className="item carousel-caption-fullwidth">
          <Container>
            <Link href={linkGen(item, type)}>
              <a
                className="ml-3 text-white"
                onClick={(e) => {
                  structuredEvent(
                    'PS-Home',
                    'klik highlight - artikel highlight',
                    item.id,
                    '',
                    '',
                    e,
                  );
                  trackerMain({
                    ga4: [
                      'eventCoreV1BasicData',
                      '',
                      'highlight_idx_click',
                      'link',
                      index + 1,
                    ],
                  });
                }}
              >
                <TextSanitizer
                  className="row featured-banner-title text-white px-3"
                  value={item.title}
                />
              </a>
            </Link>

            <div className="row featured-banner-author font-weight-medium px-3 pb-2">
              <img className="img-fluid author-picture-20" src={`${avatar}`} />
              <span>
                <Link
                  href={`/authors/${item.author_guids}/${item.author_name}?type=stories`}
                >
                  <a
                    className="ml-2"
                    onClick={(e) => {
                      structuredEvent(
                        'PS-Home',
                        'klik author - artikel highlight',
                        item.author_guids,
                        '',
                        '',
                        e,
                      );

                      trackerMain({
                        ga4: [
                          'eventCoreV1BasicData',
                          '',
                          'highlight_profil_penulis_click',
                          'link',
                          item.author_guids,
                        ],
                      });
                    }}
                  >
                    {item.author_name}
                  </a>
                </Link>

                <img
                  src="/static/images/dot_white.png"
                  className="img-dot mx-1"
                />

                <Moment
                  locale="id"
                  format="D MMMM YYYY"
                  className=""
                  locale={props.language}
                >
                  {item.published_at}
                </Moment>
              </span>
            </div>
          </Container>
        </div>
      </CarouselItem>
    );
  }); // end slides

  return (
    <div>
      <div className="position-relative" {...handlers}>
        <a
          className="carousel-control-prev"
          role="button"
          tabIndex="0"
          onClick={() => {
            previous();
            structuredEvent(
              location,
              'click arrow slider - main content slider',
              'prev',
              '',
              '',
            );
          }}
        >
          <img
            src="/static/images/arrow_left.svg"
            alt="prev-slider"
            title="previous"
          />
        </a>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          touch
          className={`crs ${
            props.roundedMobile ? 'rounded-carousel-mobile' : ''
          }`}
          interval={5000}
        >
          {slides}
          <CarouselIndicators
            items={props.data}
            activeIndex={activeIndex}
            className="dot pt-2 pt-md-0"
            onClickHandler={goToIndex}
          />
        </Carousel>
        <a
          className="carousel-control-next"
          role="button"
          tabIndex="0"
          onClick={() => {
            next();
            structuredEvent(
              location,
              'click arrow slider - main content slider',
              'next',
              '',
              '',
            );
          }}
        >
          <img
            src="/static/images/arrow_right.svg"
            alt="next-slider"
            title="next"
          />
        </a>
      </div>
    </div>
  );
};

export const SliderMain = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === props.data.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? props.data.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => previous(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // location defined for event tracker
  const { location } = props;

  const slides = props.data.map((item, index) => {
    const type = props.type || item.type.toLowerCase();
    const imageFile = item.image.substring(item.image.lastIndexOf('/') + 1);
    const imageLarge = item.image.replace(/\/[^\/]*$/, `/large_${imageFile}`);
    const imageMedium = item.image.replace(/\/[^\/]*$/, `/medium_${imageFile}`);

    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={`${item.guid}-slider-main`}
        className={`${
          props.roundedMobile ? 'rounded-carousel-mobile' : ''
        } main`}
      >
        {index > 0 ? (
          <LazyLoadImage
            title={item.title}
            alt={item.title}
            effect="opacity"
            width="100%"
            src={imageSelector(item.image, 'large')}
            className="img-fluid main-slider"
          />
        ) : (
          <picture>
            <source media="(max-width: 799px)" srcSet={imageMedium} />
            <source media="(min-width: 800px)" srcSet={imageLarge} />
            <img
              src={imageLarge}
              alt={item.altText}
              className="img-fluid main-slider"
            />
          </picture>
        )}

        {/* */}

        <div className="carousel-caption p-md-5">
          <div>
            <span className="text-capitalize categories">{item.type}</span>
            <a
              href={linkGen(item, type)}
              className="text-white"
              onClick={() => {
                trackerMain({
                  snowplow: [
                    location,
                    'click title - main content slider',
                    `${item.guid}`,
                    '',
                    `${index}`,
                  ],
                  ga4: [
                    'eventCoreV1Article',
                    location,
                    'section_main_content_slider',
                    'link_click',
                    'title highlighted article',
                    activeIndex,
                    `${item.title}`,
                    `${item.guid}`,
                  ],
                });
                trackerMain({
                  ga4: [
                    'eventCoreV1Article',
                    location,
                    'section_main_content_slider',
                    'link_click',
                    'title highlighted article',
                    activeIndex,
                    `${item.title}`,
                    `${item.guid}`,
                  ],
                });
              }}
            >
              <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
              {/* {props.question && (<div className="body d-none d-md-block" dangerouslySetInnerHTML={{ __html: item.question }} />)} */}
            </a>
            <p className=" d-none d-md-block">{item.sub_title}</p>
            {props.hideSelengkapnya || (
              <a
                href={linkGen(item, type)}
                color="secondary"
                className=" btn btn-secondary rounded mt-2 d-none d-md-inline-flex align-items-center text-primary"
                onClick={() => {
                  trackerMain({
                    snowplow: [
                      location,
                      'click button - main content slider',
                      `${item.guid}`,
                      '',
                      `${index}`,
                    ],
                    ga4: [
                      'eventCoreV1Article',
                      location,
                      'section_main_content_slider',
                      'button_click',
                      'button selengkapnya',
                      activeIndex,
                      `${item.title}`,
                      `${item.guid}`,
                    ],
                  });
                }}
              >
                Selengkapnya
                <FontAwesome className="ml-2" name="arrow-right" />
              </a>
            )}
          </div>
        </div>
      </CarouselItem>
    );
  });
  const thumbNav = props.data.map((item, index) => {
    const type = props.type || item.type.toLowerCase();
    const imageFile = item.image.substring(item.image.lastIndexOf('/') + 1);
    const imageSmall = item.image.replace(/\/[^\/]*$/, `/small_${imageFile}`);
    return (
      <div className="thumb-nav" key={`${item.guid}-thumbnav`}>
        <div
          className={`${
            activeIndex == index ? 'overlay' : ''
          } thumb-nav-container mb-2`}
          onClick={() => {
            goToIndex(index);
            trackerMain({
              snowplow: [
                location,
                'click mini box - main content slider',
                `${item.guid}`,
                '',
                '',
              ],
              ga4: [
                'eventCoreV1Article',
                location,
                'section_main_content_slider',
                'click',
                'index mini box article slider',
                index,
                `${item.title}`,
                `${item.guid}`,
              ],
            });
          }}
        >
          <LazyLoadImage
            title={item.altText}
            alt={item.altText}
            effect="opacity"
            width="100%"
            src={imageSmall}
            className="img-fluid"
          />
          {/* <img src={} alt={item.altText} className="img-fluid" /> */}
        </div>

        <a
          href={linkGen(item, type)}
          onClick={() => {
            trackerMain({
              snowplow: [
                location,
                'click title thumbnail - main content slider',
                `${item.guid}`,
                '',
                '',
              ],
              ga4: [
                'eventCoreV1Article',
                location,
                'section_main_content_slider',
                'link_click',
                'index mini box title article',
                index,
                `${item.title}`,
                `${item.guid}`,
              ],
            });
          }}
          className={`small line-clamp-2 ${
            activeIndex == index
              ? 'text-main font-weight-semibold'
              : 'text-muted'
          }`}
        >
          <span dangerouslySetInnerHTML={{ __html: item.title }} />
        </a>
      </div>
    );
  });

  return (
    <div>
      <div
        className={`position-relative ${
          props.paddingMobile ? 'p-3 p-md-0' : ''
        }`}
        {...handlers}
      >
        <a
          className="carousel-control-prev d-md-block d-none"
          role="button"
          tabIndex="0"
          onClick={() => {
            previous();
            trackerMain({
              snowplow: [
                location,
                'click arrow slider - main content slider',
                'prev',
                '',
                '',
                '',
              ],
              ga4: [
                'eventCoreV1Index',
                location,
                'section_main_content_slider',
                'button_click',
                'button prev',
                `${activeIndex}`,
                '',
                '',
              ],
            });
          }}
        >
          <img
            src="/static/images/chevron_left.svg"
            alt="prev-slider"
            title="previous"
          />
        </a>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          touch
          className={`${props.roundedMobile ? 'rounded-carousel-mobile' : ''}`}
        >
          {slides}
          <CarouselIndicators
            items={props.data}
            activeIndex={activeIndex}
            className="strip d-md-none "
            onClickHandler={goToIndex}
          />
        </Carousel>
        <a
          className="carousel-control-next d-md-block d-none"
          role="button"
          tabIndex="0"
          onClick={() => {
            next();
            trackerMain({
              snowplow: [
                location,
                'click arrow slider - main content slider',
                'next',
                '',
                '',
                '',
              ],
              ga4: [
                'eventCoreV1Index',
                location,
                'section_main_content_slider',
                'button_click',
                'button next',
                `${activeIndex}`,
                '',
                '',
              ],
            });
          }}
        >
          <img
            src="/static/images/chevron_right.svg"
            alt="next-slider"
            title="next"
          />
        </a>
      </div>
      <div
        className={`justify-content-center flex-row my-2 d-none ${
          props.hideThumb ? '' : 'd-md-flex'
        }`}
      >
        {thumbNav}
      </div>
    </div>
  );
};

export const SliderMedia = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const dataMedia = props.data;
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === dataMedia.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? dataMedia.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  const published_at = (data) => (
    <Moment locale="id" format="dddd, DD MMM YYYY" className="__time">
      {data.published_at}
    </Moment>
  );

  const slides = dataMedia.map((item, index) => {
    const target = item.type == 'Youtube' ? { target: '_blank' } : {};
    const id = item.type == 'Youtube' ? item.id : item.guid;
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={`${index}-mediaVideo`}
        className="media"
      >
        {/* <img src={item.image} alt={item.altText} className="img-fluid" /> */}
        <LazyLoadImage
          title={item.altText}
          effect="opacity"
          width="100%"
          src={item.image}
          alt={item.altText}
          className="img-fluid"
        />
        <div>
          <div className="carousel-caption-media  d-none d-md-block p-5">
            <a
              href={item.link}
              {...target}
              onClick={() =>
                trackerMain({
                  snowplow: [
                    props.location,
                    'click link artikel - blok foto and video - main slider',
                    `${id}`,
                    '',
                    '',
                  ],
                  ga4: [
                    'eventCoreV1ArticleFotoVideo',
                    props.location,
                    'section_foto_dan_video',
                    'click',
                    'title highlighted article ',
                    index,
                    `${item.title}`,
                    `${id}`,
                  ],
                })
              }
            >
              <h3>{item.title}</h3>
            </a>
            <span>{published_at(item)}</span>
          </div>
        </div>
      </CarouselItem>
    );
  });
  const itemList = dataMedia.map((item, index) => (
    <div className="content border-bottom active" key={`${index}-media`}>
      <div className={activeIndex == index ? 'caret-indicator' : 'd-none'}>
        <i className="fa fa-caret-left mr-2" />
      </div>
      <a
        className={activeIndex == index ? 'active' : ''}
        onClick={() => {
          trackerMain({
            snowplow: [
              props.location,
              'click link artikel - blok foto and video',
              `${item.guid}`,
              '',
              `${index}`,
            ],
            ga4: [
              'eventCoreV1ArticleFotoVideo',
              props.location,
              'section_foto_dan_video',
              'link_click',
              'index lihat article',
              index,
              `${item.title}`,
              `${item.type == 'Youtube' ? item.id : item.guid}`,
            ],
          });
          goToIndex(index);
        }}
      >
        <span>{item.title}</span>
      </a>
    </div>
  ));
  return (
    <Row>
      <Col md={8} className="">
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          {slides}
          {props.indicator && (
            <CarouselIndicators
              items={dataMedia}
              activeIndex={activeIndex}
              className="strip"
              onClickHandler={goToIndex}
            />
          )}
        </Carousel>
      </Col>
      <Col md={4} className="">
        <div id="section-latest" className="section">
          <div className="section-head d-flex justify-content-between align-items-center border-bottom">
            <h3>Foto dan Video</h3>
          </div>
          <div className="section-content">
            {itemList}
            <div className="read-more">
              <a
                href="/fotovideo"
                className="text-uppercase"
                onClick={() =>
                  trackerMain({
                    snowplow: [
                      props.location,
                      'click link - blok foto and video - lihat semua',
                      '',
                      '',
                      '',
                    ],
                    ga4: [
                      'eventCoreV1BasicData',
                      props.location,
                      'section_foto_dan_video',
                      'button_click',
                      'button lihat semua foto dan video',
                      '',
                      '',
                      '',
                    ],
                  })
                }
              >
                <span>
                  Lihat Semua <i className="fa fa-arrow-right ml-2" />{' '}
                </span>
              </a>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export const SliderProduct = (props) => {
  const [isOpen, setIsOpen] = useState([true, false, false]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const dataMedia = props.data;
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === dataMedia.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? dataMedia.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  const published_at = (data) => (
    <Moment locale="id" format="dddd, DD MMM YYYY" className="__time">
      {data.published_at}
    </Moment>
  );

  const slides = dataMedia.map((item) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={`${item.id}-product`}
    >
      <LazyLoadImage
        effect="blur"
        width="100%"
        src={item.image}
        alt={item.altText}
        className="img-fluid"
      />
    </CarouselItem>
  ));
  const ProductItem = ({ itemData }) => (
    <div className="accordion-body border-bottom py-3">
      <div
        className="d-flex justify-content-between"
        onClick={() => {
          trackerMain({
            snowplow: [
              props.location,
              `click title - blok enterprise solution - ${itemData.title}`,
              '',
              '',
              '',
            ],
            ga4: [
              'eventCoreV1BasicData',
              props.location,
              'section_enterprise_solution',
              'button_click',
              `button unhide ${itemData.id == 1 ? 'rcs' : 'exdoma'}`,
              '',
              '',
              '',
            ],
          });
          goToIndex(itemData.id - 1);
        }}
      >
        <span>{itemData.title}</span>
        <i
          className={`fa ${
            itemData.id - 1 == activeIndex ? 'fa-caret-up' : 'fa-caret-down'
          } mr-2`}
        />
      </div>
      <Collapse isOpen={itemData.id - 1 == activeIndex}>
        <div className="accordion-content section-content">
          <p className="mb-2">{itemData.description}</p>
          <div className="read-more">
            <a
              href={itemData.link}
              target="_blank"
              rel="noreferrer"
              className="d-md-block d-none text-uppercase"
              onClick={() =>
                trackerMain({
                  snowplow: [
                    props.location,
                    `click link - blok enterprise solution - ${itemData.link}`,
                    '',
                    '',
                    '',
                  ],
                  ga4: [
                    'eventCoreV1BasicData',
                    props.location,
                    'section_enterprise_solution',
                    'button_click',
                    `button lihat selengkapnya ${
                      itemData.id == 1 ? 'rcs' : 'exdoma'
                    }`,
                    '',
                    '',
                    '',
                  ],
                })
              }
            >
              Selengkapnya <i className="fa fa-arrow-right ml-2" />
            </a>
          </div>
        </div>
      </Collapse>
    </div>
  );
  const listItem = props.data.map((dataItem) => (
    <ProductItem key={`${dataItem.id}-product-item`} itemData={dataItem} />
  ));
  return (
    <Row>
      <Col md={6} className="">
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          {slides}
        </Carousel>
      </Col>
      <Col md={6} className="">
        <div className="accordion">{listItem}</div>
      </Col>
    </Row>
  );
};

const eventItem = (dataItem, location, index = 0, type = 'desktop') => {
  const title = dataItem.title && dataItem.title;
  const guid = dataItem.guid && dataItem.guid;
  const image = dataItem.image && dataItem.image;
  const category = dataItem.type && dataItem.type;
  const published_at = dataItem.published_at && (
    <Moment locale="id" format="DD MMM YYYY" className="__time">
      {dataItem.published_at}
    </Moment>
  );
  return (
    <Col
      md={3}
      key={guid}
      className={`${type == 'mobile' ? 'col-10 p-1' : 'col-11'}`}
    >
      <Media className={`${type == 'mobile' ? 'event-mobile p-3' : ''}`}>
        <Media body>
          <a
            href={linkGen(dataItem, 'talks')}
            onClick={() =>
              trackerMain({
                snowplow: [
                  location,
                  'click link artikel - blok events',
                  `${guid}`,
                  '',
                  index,
                ],
                ga4: [
                  'eventCoreV1Event',
                  location,
                  'section_event',
                  'image_click',
                  'index event',
                  index,
                  `${title}`,
                  `${guid}`,
                ],
              })
            }
          >
            <LazyLoadImage
              effect="blur"
              src={imageSelector(image, 'medium')}
              alt={title}
              className="img-fluid event-item media-object"
            />
          </a>
          <div className="d-flex flex-column my-1">
            <span className="category">{category}</span>
            <a
              href={linkGen(dataItem, 'talks')}
              className="limit-2"
              onClick={() =>
                trackerMain({
                  snowplow: [
                    location,
                    'click link artikel - blok events',
                    `${guid}`,
                    '',
                    index,
                  ],
                  ga4: [
                    'eventCoreV1Event',
                    location,
                    'section_event',
                    'link_click',
                    'index event',
                    index,
                    `${title}`,
                    `${guid}`,
                  ],
                })
              }
            >
              <span>{title}</span>
            </a>
          </div>
        </Media>
      </Media>
    </Col>
  );
};

export const EventsListSlider = (props) => {
  const eventAllItem = props.data.items;
  const itemList = [];
  for (let i = 0; i < eventAllItem.length; i++) {
    itemList.push(eventItem(eventAllItem[i], props.location, i));
  }
  const listItem = (
    <>
      <div className="container-fluid d-none d-md-block">
        <div
          className="row flex-row flex-nowrap overflow-auto"
          id="containerElement"
        >
          {itemList}
        </div>
      </div>
    </>
  );
  return listItem;
};
export const EventListMobile = (props) => {
  const eventAllItem = props.data.items;
  const itemList = [];
  for (let i = 0; i < eventAllItem.length; i++) {
    itemList.push(eventItem(eventAllItem[i], props.location, i, 'mobile'));
  }
  const listItem = (
    <>
      <div className="container-fluid d-md-none d-block">
        <div
          className="row flex-row flex-nowrap overflow-auto"
          id="containerElement"
        >
          {itemList}
        </div>
      </div>
      <div className="read-more my-3  d-md-none">
        <a
          href="/talks"
          onClick={() =>
            trackerMain({
              snowplow: [
                'HOL - New Homepage',
                'click link - blok events - lihat semua',
                '',
                '',
                '',
              ],
              ga4: [
                'eventCoreV1BasicData',
                location,
                'section_event',
                'button_click',
                'button lihat semua event',
                '',
                '',
                '',
              ],
            })
          }
          className="btn btn-more btn-outline-primary btn-block"
        >
          <span>Lihat Semua Event </span>
        </a>
      </div>
    </>
  );
  return listItem;
};

export const InfoGrafisSlider = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    const infoGrafisData = props.data;
    if (animating) return;
    const nextIndex =
      activeIndex === infoGrafisData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    const infoGrafisData = props.data;
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? infoGrafisData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const infoGrafisData = props.data;
  const itemsCol = [];
  for (let i = 0; i < infoGrafisData.length; i++) {
    itemsCol.push(
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={infoGrafisData[i].guid}
      >
        <div className="position-relative px-3">
          <Media
            left
            href={linkGen(infoGrafisData[i], 'infografis')}
            onClick={() =>
              trackerMain({
                snowplow: [
                  props.location,
                  'click image - blok infografik',
                  `${infoGrafisData[i].guid}`,
                  '',
                  i,
                ],
                ga4: [
                  'eventCoreV1Infografic',
                  props.location,
                  'section_infografik',
                  'link_click',
                  'index infografik',
                  i,
                  `${infoGrafisData[i].title}`,
                  `${infoGrafisData[i].guid}`,
                ],
              })
            }
          >
            <LazyImageWithFallback
              title={infoGrafisData[i].title}
              alt={infoGrafisData[i].title}
              fallback="https://static.hukumonline.com/frontend/default/images/kaze/default.jpg"
              src={imageSelector(infoGrafisData[i].image, 'large')}
              className="img-fluid media-object"
            />
          </Media>
        </div>
      </CarouselItem>,
    );
  }
  const itemList = infoGrafisData.map((item, index) => (
    <div className="position-relative col-11 px-2" key={`${index}-infografik`}>
      <Media
        left
        href={linkGen(item, 'klinikGrafis')}
        onClick={() =>
          trackerMain({
            snowplow: [
              props.location,
              'click image - blok infografik',
              `${item.guid}`,
              '',
              index,
            ],
            ga4: [
              'eventCoreV1Infografic',
              props.location,
              'section_infografik',
              'link_click',
              'index infografik',
              index,
              `${item.title}`,
              `${item.guid}`,
            ],
          })
        }
      >
        <LazyImageWithFallback
          title={item.image}
          alt={item.image}
          fallback="https://static.hukumonline.com/frontend/default/images/kaze/default.jpg"
          effect="opacity"
          src={imageSelector(item.image, 'default')}
          className="img-fluid rounded media-object"
        />
      </Media>
    </div>
  ));

  return (
    <>
      <div>
        <div
          className={`position-relative ${
            props.desktopHide ? 'd-none' : 'd-md-block d-none'
          } `}
        >
          <a
            className="carousel-control-prev event-list d-md-block d-none"
            role="button"
            tabIndex="0"
            onClick={() => {
              previous();
              trackerMain({
                snowplow: [
                  props.location,
                  'click arrow slider - blok infografik',
                  'prev',
                  '',
                  '',
                ],
                ga4: [
                  'eventCoreV1Infografic',
                  props.location,
                  'section_infografik',
                  'button_click',
                  'button prev',
                  activeIndex,
                  '',
                  '',
                ],
              });
            }}
          >
            <img
              src="/static/images/chevron_left_dark.svg"
              alt="previous-slider"
              title="previous"
            />
          </a>
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            {itemsCol}
          </Carousel>
          <a
            className="carousel-control-next  event-list  d-md-block d-none"
            role="button"
            tabIndex="0"
            onClick={() => {
              next();
              trackerMain({
                snowplow: [
                  props.location,
                  'click arrow slider - blok infografik',
                  'next',
                  '',
                  '',
                ],
                ga4: [
                  'eventCoreV1Infografic',
                  props.location,
                  'section_infografik',
                  'button_click',
                  'button next',
                  activeIndex,
                  '',
                  '',
                ],
              });
            }}
          >
            <img
              src="/static/images/chevron_right_dark.svg"
              alt="next-slider"
              title="next"
            />
          </a>
        </div>
        <CarouselIndicators
          items={itemsCol}
          activeIndex={activeIndex}
          className={`dot position-relative ${
            props.desktopHide ? 'd-none' : 'd-md-flex d-none'
          }`}
          onClickHandler={goToIndex}
        />
        <div className="container-fluid d-md-none d-block">
          <div
            className="row flex-row flex-nowrap overflow-auto"
            id="containerElement"
          >
            {itemList}
          </div>
        </div>
        <div className="read-more my-3">
          <a
            href="/klinik/grafis"
            className="btn body btn-more btn-outline-primary btn-block d-md-none "
            onClick={() =>
              trackerMain({
                snowplow: [
                  props.location,
                  'click link - blok infografik - lihat semua',
                  '',
                  '',
                  '',
                ],
                ga4: [
                  'eventCoreV1BasicData',
                  'New - New Homepage',
                  'section_infografik',
                  'button_click',
                  'button selengkapnya',
                  '',
                  '',
                  '',
                ],
              })
            }
          >
            <span>Lihat Semua Infografik </span>
          </a>
        </div>
      </div>
    </>
  );
};
