import React from 'react';
import Container from 'reactstrap/lib/Container';
import { IoChevronForward, IoListSharp, IoPersonCircle } from 'react-icons/io5';
import { BsBoxArrowInRight, BsFillStarFill, BsGlobe } from 'react-icons/bs';
import PropTypes from 'prop-types';
import { FaChevronRight } from 'react-icons/fa';
import Moment from 'react-moment';
import { useRouter } from 'next/router';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { IDENTITY_URL, BASE_URL } = publicRuntimeConfig;

const PersonalisasiMenu = ({
  setMenu,
  data,
  toggleMenu,
  returnURL,
  userDetail,
  i18n,
  t,
}) => {
  const router = useRouter();

  return (
    <>
      <Container className="py-4 d-flex flex-column">
        <div
          className={
            'personalisasi-greeting d-flex justify-content-between py-4'
          }
        >
          <div className="d-flex flex-column">
            <span className="header-3 font-weight-medium">Halo,</span>
            <span className="header-3 font-weight-semibold">
              {userDetail.fullName}
            </span>
          </div>
          {userDetail.isPaidMember && (
            <div>
              <img
                src={`${BASE_URL}/static/images/flags/flags-iso/shiny/24/${i18n.language}.png`}
                className="mr-2"
                alt="Active Language"
              />
            </div>
          )}
        </div>
        <div
          className={'personalisasi-info d-flex justify-content-between py-4'}
        >
          <div className="d-flex flex-column">
            <span className="header-3 font-weight-medium">Anda,</span>
            <div className={'d-flex align-items-center'}>
              <span className="header-3 font-weight-bold text-primary text-capitalize">
                {userDetail.groups}
              </span>
              {userDetail.isPaidMember && (
                <BsFillStarFill
                  fontSize="24px"
                  className="ml-2 text-secondary"
                />
              )}
            </div>
            {userDetail.isPaidMember && (
              <p className={'body text-main my-3'}>
                Selamat! Anda adalah member Pro! Masa berlangganan Anda berlaku
                s/d{' '}
                <Moment locale={i18n.language} format="D MMMM YYYY">
                  {userDetail.subscription_expired_at}
                </Moment>
              </p>
            )}
            {!userDetail.isPaidMember && (
              <p className={'body text-main my-3'}>
                Segera upgrade paket berlangganan Anda. Dapatkan fitur lebih
                lengkap
              </p>
            )}

            {!userDetail.isPaidMember && (
              <div className={'d-flex align-items-center'}>
                <a
                  href={
                    'https://pro.hukumonline.com/paket?utm_source=website&utm_medium=navbar&utm_campaign=home_menu_berlangganan'
                  }
                >
                  <FaChevronRight
                    fontSize="16px"
                    className="mr-2 text-primary"
                  />
                  <span className="font-weight-bold text-primary body">
                    Mulai Berlangganan Sekarang
                  </span>
                </a>
              </div>
            )}
            {userDetail.isPaidMember && userDetail.isExpired && (
              <div className={'d-flex align-items-center'}>
                <a
                  href={
                    'https://pro.hukumonline.com/paket?utm_source=website&utm_medium=navbar&utm_campaign=home_menu_berlangganan'
                  }
                >
                  <FaChevronRight
                    fontSize="16px"
                    className="mr-2 text-primary"
                  />
                  <span className="font-weight-bold text-primary body">
                    Perpanjang Member Sekarang
                  </span>
                </a>
              </div>
            )}
          </div>
        </div>
      </Container>
      <div>
        <hr className={'my-4 flex-grow-1'} />
      </div>

      <Container className={'py-4'}>
        <div
          className={'personalisasi-menu d-flex justify-content-between py-3'}
        >
          <a
            onClick={() => {
              structuredEvent(
                'navbar',
                'link_click - logo profile',
                '',
                '',
                '',
              );
              pushGa4Generic({
                event: 'Navbar',
                action: 'link_click',
                component: 'logo profile',
              });
            }}
            href={IDENTITY_URL}
          >
            <div className={'personalisasi-profile d-flex flex-column '}>
              <div className={'d-flex align-items-center'}>
                <IoPersonCircle fontSize="18px" className="text-primary mr-2" />{' '}
                <span className={'paragraph text-primary font-weight-semibold'}>
                  Profile
                </span>
              </div>
            </div>
          </a>
        </div>
        {/* {userDetail.isPaidMember && (
        <div
          className={"personalisasi-menu d-flex justify-content-between py-3"}
        >
          <div className={"personalisasi-profile d-flex flex-column "}>
            <a
              href={"#!"}
              onClick={() => {
                toggleMenu(
                  data,
                  {
                    name: "lvl2",
                    isOpen: !data.isOpen.lvl2,
                    menuActiveLvl1: "personalisasiMenu",
                    menuActiveLvl2: "bahasaMenu"
                  },
                  setMenu
                );
                structuredEvent("navbar", "click  - bahasa ", "", "")
                pushGa4Generic({
                  event: 'Navbar',
                  action: 'link_click',
                  component: 'bahasa'
                })
              }}
            >
              <div className={"d-flex align-items-center"}>
                <BsGlobe fontSize="18px" className="text-primary mr-2" />{" "}
                <span className={"paragraph text-primary font-weight-semibold"}>
                  {t("language")}
                </span>
              </div>
            </a>
          </div>
        </div>
      )} */}

        <div
          className={'personalisasi-menu d-flex justify-content-between py-3'}
        >
          <button
            type="button"
            onClick={() => {
              structuredEvent('navbar', 'click url  - logout', '', '');
              pushGa4Generic({
                event: 'Navbar',
                action: 'link_click',
                component: 'logout',
              });
              router.push(`${IDENTITY_URL}/user/logout/returnUrl/${returnURL}`);
            }}
          >
            <div className={'personalisasi-profile d-flex flex-column '}>
              <div className={'d-flex align-items-center'}>
                <BsBoxArrowInRight
                  fontSize="18px"
                  className="text-primary mr-2"
                />{' '}
                <span className={'paragraph text-primary font-weight-semibold'}>
                  Keluar
                </span>
              </div>
            </div>
          </button>
        </div>
        <div
          className="d-flex align-items-end my-5 justify-content-center"
          style={{ position: 'absolute', bottom: '0', width: '100%' }}
        >
          <img
            src={`${BASE_URL}/static/images/main/ask.svg`}
            alt="Icon Ask"
            className="mr-2"
          />
          <span className="body font-weight-medium">
            Ada Pertanyaan?{' '}
            <a
              href="https://www.hukumonline.com/produk/hubungi-kami/id/"
              className="font-weight-semibold text-primary"
            >
              {' '}
              Hubungi Kami{' '}
            </a>
          </span>
        </div>
      </Container>
    </>
  );
};

PersonalisasiMenu.propTypes = {
  setMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
  currentUrl: PropTypes.string.isRequired,
};

export default PersonalisasiMenu;
